import './github/projects/edit'

import type {MoveEvent, SortableEvent} from '@github/sortablejs'
import {
  addColumnNav,
  deleteColumnNav,
  displayUpdateMessage,
  updateColumnNav,
  updateProject,
  updateProjectColumnAutomation,
} from './github/project-updater'
import {addThrottledInputEventListener, removeThrottledInputEventListener} from './github/throttled-input'
import {afterRemote, remoteForm} from '@github/remote-form'
import {
  applyFilter,
  filterCard,
  filterCards,
  hideSuggestions,
  queryContainsQualifier,
  updateFilterState,
  updateFilteredCardCounts,
  updateSuggestionsDisplay,
} from './github/project-cards-filter'
import {changeValue, requestSubmit} from '@github-ui/form-utils'
import {fetchPoll, fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import {onFocus, onKey} from './github/onfocus'

import type AutocompleteElement from '@github/auto-complete-element'
import type DetailsDialogElement from '@github/details-dialog-element'
import type {IncludeFragmentElement} from '@github/include-fragment-element'
import type {ModalDialogElement} from '@primer/view-components/app/components/primer/alpha/modal_dialog'
import type {RemoteFormHandler} from '@github/remote-form'
import type RemoteInputElement from '@github/remote-input-element'
import {debounce} from '@github/mini-throttle'
import {dialog} from '@github-ui/details-dialog'
import {enableKeyboardMovements} from './github/projects/keyboard-mover'
import {enableTaskList} from './github/behaviors/task-list'
import {eventToHotkeyString} from '@github-ui/hotkey'
import {fromEvent} from '@github-ui/subscription'
import hashChange from './github/behaviors/hash-change'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {parseHTML} from '@github-ui/parse-html'
import {replaceState} from '@github-ui/browser-history-state'
import {sendStats} from '@github-ui/stats'
import {toggleDetailsTarget} from './github/behaviors/details'
import {updateContent} from '@github-ui/updatable-content'
import verifySsoSession from './github/sso'
import {loaded} from '@github-ui/document-ready'

// ****************************************************************************
// Helpers
let clientUID: string

const clientUIDElem = document.querySelector('.js-client-uid')
if (clientUIDElem) {
  clientUID = clientUIDElem.getAttribute('data-uid')!
}

// This is a wrapper around remoteForm that lets us show an informative dialog
// when a projects-related AJAX request returns an error.
function remoteProjectForm(selector: string, fn: RemoteFormHandler) {
  remoteForm(selector, async function (form, kicker, req) {
    try {
      await fn(form, kicker, req)
    } catch (error) {
      // If we get an error response from one of our project forms, show a
      // dialog prompting the user to reload.
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      if (error.response) {
        await openDialog('project-update-error')
      }

      throw error
    }
  })
}

// Wrapper for remoteForm that hides form actions after the form is submitted
// This is primary used for waiting for a job status to indicate the job is done
// Arguments:
// - selector: form selector, your form should have:
//   1. `.form-actions` containing your submit/cancel buttons
//   2. `.js-project-loader` with a loading UI
// - errorModal: error modal selector if your form submission fails
// - fn: your callback function
function remoteFormDisabledOnSubmit(selector: string, errorModal: string, fn: RemoteFormHandler) {
  remoteForm(selector, async function (form, kicker, req) {
    const buttons = form.querySelector<HTMLElement>('.form-actions')!
    const loader = form.querySelector<HTMLElement>('.js-project-loader')!

    /* eslint-disable-next-line github/no-d-none */
    buttons.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    loader.classList.remove('d-none')

    try {
      await fn(form, kicker, req)
    } catch (error) {
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      if (error.response) {
        await openDialog(errorModal)
      }

      /* eslint-disable-next-line github/no-d-none */
      buttons.classList.remove('d-none')
      /* eslint-disable-next-line github/no-d-none */
      loader.classList.add('d-none')
      throw error
    }
  })
}

function closeDialog(element: Element) {
  element.closest<DetailsDialogElement>('details-dialog')!.toggle(false)
}

async function openDialog(dialogID: string, opener?: Element): Promise<HTMLElement> {
  const dialogTemplate = document.querySelector<HTMLTemplateElement>(`#${dialogID}`)!
  const openedDialog = await dialog({
    content: dialogTemplate.content.cloneNode(true) as DocumentFragment,
    dialogClass: 'project-dialog',
  })

  if (opener) {
    opener.setAttribute('aria-expanded', 'true')
  }

  openedDialog.addEventListener(
    'dialog:remove',
    function () {
      if (opener) {
        opener.setAttribute('aria-expanded', 'false')
      }
    },
    {once: true},
  )

  return openedDialog
}

function populateFormFromCard(form: HTMLFormElement, card: Element | null) {
  const cardIDInput = form.elements.namedItem('card_id') as HTMLInputElement
  const contentIDInput = form.elements.namedItem('content_id') as HTMLInputElement
  const contentTypeInput = form.elements.namedItem('content_type') as HTMLInputElement

  if (!card) {
    cardIDInput.value = ''
    contentIDInput.value = ''
    contentTypeInput.value = ''
    return
  }

  const cardID = card.getAttribute('data-card-id')
  if (cardID) {
    cardIDInput.value = cardID
    contentIDInput.value = ''
    contentTypeInput.value = ''
  } else {
    cardIDInput.value = ''
    contentIDInput.value = card.getAttribute('data-content-id')!
    contentTypeInput.value = card.getAttribute('data-content-type')!
  }

  ;(form.elements.namedItem('client_uid') as HTMLInputElement).value = clientUID
}

function updateColumnCount(column: Element, change: number) {
  const counter = column.querySelector<HTMLElement>('.js-column-card-count')!
  const counterValue = parseInt(counter.textContent!.trim())
  const columnID = column.getAttribute('data-id')

  // Increment/decrement unless change is 0, then just set it to 0!
  let newCounterValue = counterValue + change
  if (change === 0) newCounterValue = 0

  // Update column header count
  counter.textContent = newCounterValue.toString()

  let responsiveCounter
  // The triage area for pending cards does not have an id
  if (columnID) {
    // Update responsive column header count
    responsiveCounter = document.querySelector<HTMLElement>(
      `.js-project-column-navigation-item[data-column-id="${columnID}"] .js-column-nav-card-count`,
    )!
    if (responsiveCounter) {
      responsiveCounter.textContent = newCounterValue.toString()
    }
  }

  // eslint-disable-next-line i18n-text/no-en
  const label = `Contains ${newCounterValue} ${newCounterValue === 1 ? 'item' : 'items'}`
  counter.setAttribute('aria-label', label)

  if (responsiveCounter) {
    responsiveCounter.setAttribute('aria-label', label)
  }
}

// Track if move was done using the keyboard
function trackKeyboardMove(form: HTMLFormElement, options?: {keyboardMove?: boolean}) {
  const keyboardInput = form.elements.namedItem('keyboard_move') as HTMLInputElement
  if (options && options.keyboardMove === true) {
    keyboardInput.value = 'true'
  } else {
    keyboardInput.value = ''
  }
}

function moveCard(card: Element, fromColumn?: Element | null, options?: {keyboardMove?: boolean}) {
  if (fromColumn) {
    cleanupPendingCards(fromColumn)
    updateColumnCount(fromColumn, -1)
  }

  const column = card.closest('.js-project-column')
  if (!column) return

  const columnID = column.getAttribute('data-id')!
  card.setAttribute('data-column-id', columnID)
  updateColumnCount(column, 1)
  cleanupSearchResults()

  const form = column.querySelector<HTMLFormElement>('.js-project-content-form')!
  form.reset()
  populateFormFromCard(form, card)

  const prevCard = card.previousElementSibling
  const prevCardInput = form.elements.namedItem('previous_card_id') as HTMLInputElement
  if (prevCard) {
    prevCardInput.value = prevCard.getAttribute('data-card-id')!
  } else {
    prevCardInput.value = ''
  }

  trackKeyboardMove(form, options)

  requestSubmit(form)
}

function handleCardDrag(event: SortableEvent) {
  const card = event.item

  // Ignore drops on the triage pane. This will happen when a card is dragged
  // from the triage pane, onto a column, and then back to triage before a drop.
  if (card.closest('.js-project-triage-pane')) return

  const from = event.from
  const fromColumn = from && from.closest('.js-project-column')
  moveCard(card, fromColumn)
  card.focus()
}

// Remove a single card from a column and update the column card count
function removeCard(card: Element) {
  const column = card.closest('.js-project-column')

  if (column) {
    updateColumnCount(column, -1)
  }

  card.remove()
}

// For bulk card archival, we empty the card container of *all* cards
// instead of removing individually because users could potentially be archiving
// 100s of cards, and there's no added benefit or UX for doing this
// Bonus: also resets the column card count to 0
function removeAllCardsFromColumn(column: Element) {
  const columnCards = column.querySelector<HTMLElement>('.js-project-column-cards')!
  columnCards.textContent = ''

  updateColumnCount(column, 0)
}

const cardIdHashRegex = /^#card-(\d+)$/

function getCardIdFromHash(): string | undefined | null {
  const captures = document.location.hash.match(cardIdHashRegex)

  if (captures) {
    // If there's a match, captures[0] contains the entire string, while
    // captures[1] contains the first sub-group.
    return captures[1]
  } else {
    return null
  }
}

function moveColumn(column: Element, options?: {keyboardMove?: boolean}) {
  const form = document.querySelector<HTMLFormElement>('.js-reorder-columns-form')!

  ;(form.elements.namedItem('column_id') as HTMLInputElement).value = column.getAttribute('data-id')!

  const previousColumn = column.previousElementSibling
  const previousColumnId = form.elements.namedItem('previous_column_id') as HTMLInputElement
  if (previousColumn) {
    previousColumnId.value = previousColumn.getAttribute('data-id')!
  } else {
    previousColumnId.value = ''
  }

  trackKeyboardMove(form, options)

  requestSubmit(form)
}

function handleColumnMove(event: MoveEvent): boolean | undefined {
  // Only allow dropping around other columns
  if (event.related && !event.related.matches('.js-project-column')) {
    return false
  }
  return undefined
}

function handleColumnUpdate(event: SortableEvent) {
  const column = event.item
  moveColumn(column)
  column.focus()
}

function cleanupPendingCards(fromColumn: Element) {
  if (fromColumn.classList.contains('js-project-pending-cards-container')) {
    if (fromColumn.querySelectorAll('.js-project-column-card').length === 0) {
      fromColumn.remove()
    }
  }
}

// Reset the search page to 1 when all cards are dragged out of a search area
function cleanupSearchResults() {
  for (const searchableCards of document.querySelectorAll('.js-project-search-cards')) {
    const pageValue = searchableCards.querySelector('.js-search-cards-next-page')
    if (pageValue instanceof HTMLInputElement) {
      const noSearchCards = searchableCards.querySelector('.js-project-column-card') == null
      if (noSearchCards) {
        pageValue.value = '1'
      }
    }
  }
}

function refreshAddCardsSearchResults() {
  const addCardsSearchForm = document.querySelector('.js-project-search-form')
  if (addCardsSearchForm instanceof HTMLFormElement) {
    requestSubmit(addCardsSearchForm)
  }
}

// Update the search query when the add cards linked repo scope is toggled
on('change', '.js-toggle-linked-repo-scope', function (event) {
  const checkbox = event.currentTarget as HTMLInputElement
  const form = checkbox.form!
  const search = form.querySelector<HTMLInputElement>('.js-project-triage-search-text')!

  const originalQuery = search.value || ''
  let newQuery: string

  // Remove all instances of 'repo:' queries, or expand scope by adding 'repo:*'
  if (checkbox.checked) {
    newQuery = originalQuery.replace(/(\s|^)repo:[^\s]*\s*/gi, '$1').trim()
  } else {
    newQuery = `${originalQuery.trim()} repo:*`
  }

  // Only send the search request of the query has changed
  // Triggers the listener and validations for the search query form
  if (newQuery !== originalQuery) {
    search.value = newQuery
    requestSubmit(form)
  }
})

function refreshArchivedCardsSearchResults() {
  const archivedCardsSearchForm = document.querySelector('.js-project-archived-cards-search-form')
  if (archivedCardsSearchForm instanceof HTMLFormElement) {
    requestSubmit(archivedCardsSearchForm)
  }
}

on('click', '.js-project-show-all-archived-cards', refreshArchivedCardsSearchResults)

// Find a card by either card id or content id+type
function findCard(cardID?: string, contentID?: string, contentType?: string): HTMLElement | undefined | null {
  let card
  if (cardID) {
    card = document.querySelector<HTMLElement>(`.js-project-column-card[data-card-id="${cardID}"]`)
  }

  if (!card && contentID && contentType) {
    card = document.querySelector<HTMLElement>(
      `.js-project-column-card[data-content-type="${contentType}"][data-content-id="${contentID}"]`,
    )
  }

  return card
}

// Swap out updated card info. Any forms with this class name will take their response
// and attempt to update any cards on the page that match it.
remoteProjectForm('.js-project-update-card', async function (form, send) {
  const response = await send.html()

  const card = response.html.querySelector<HTMLElement>('*')!
  const cardToUpdate = findCard(
    card.getAttribute('data-card-id')!,
    card.getAttribute('data-content-id')!,
    card.getAttribute('data-content-type')!,
  )
  const focusUpdatedCard = cardToUpdate && cardToUpdate === cardToUpdate.ownerDocument.activeElement ? true : false

  if (cardToUpdate) {
    cardToUpdate.replaceWith(card)
  }
  if (focusUpdatedCard) {
    card.focus()
  }
})

// Clone the project and redirect when the job is finished
// If the request to clone fails: generic error modal to try again
// If the background job fails: modal with link to new, empty board
// If successful: redirect!
remoteFormDisabledOnSubmit('.js-project-clone-form', 'project-clone-error', async function (form, send) {
  const response = await send.json()
  const projectUrl = response.json.project_url
  const jobUrl = response.json.job_status_url

  try {
    await fetchPoll(jobUrl)
    window.location = projectUrl
  } catch (error) {
    if (projectUrl) {
      // Failed background job
      const openedDialog = await openDialog('project-clone-partial-error')
      const link = openedDialog.querySelector<HTMLElement>('.js-cloned-board-link')!
      link.setAttribute('href', projectUrl)
    } else {
      // Failed creation (pretty unlikely someone would hit this)
      await openDialog('project-clone-error')
    }
  }
})

// Error when trying to convert an already converted note
function isNoteAlreadyConvertedToIssue(response: Response) {
  try {
    // @ts-expect-error `error_code` is not a property on the json method?
    return response && response.json && response.json.error_code === 'note_already_converted_to_issue'
  } catch (error) {
    return false
  }
}

remoteProjectForm('.js-convert-note-to-issue-form', async function (form, send) {
  try {
    await send.text()
    closeDialog(form)
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (isNoteAlreadyConvertedToIssue(error.response)) {
      const errorContainer = form.querySelector<HTMLElement>('.js-convert-note-error-container')!
      errorContainer.textContent = ''
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      errorContainer.append(parseHTML(document, error.response.json.error_html))
    } else {
      throw error
    }
  }
})

remoteProjectForm('.js-change-project-state-form', async function (form, send) {
  // if there is an active element, blur so live updates work
  const activeElement = document.activeElement
  if (activeElement instanceof HTMLElement && form.contains(activeElement)) {
    // details-dialog is managing focus to a place we are trying live update
    // we can't remove the manual `blur` here until that is resolved
    // see https://github.com/github/details-dialog-element/issues/24
    // eslint-disable-next-line github/no-blur
    activeElement.blur()
  }

  await send.text()
  closeDialog(form)
})

// Open edit project modal with description field focused
on('click', '.js-add-project-description', async function () {
  const modal = await openDialog('edit-project')
  modal.querySelector<HTMLElement>('#edit-project-name')!.removeAttribute('autofocus')
  modal.querySelector<HTMLElement>('#edit-project-body')!.setAttribute('autofocus', '')
})

// Project cloning modal
// Toggle the publc/private options based on repo or org owner
// Ensure repo projects are never set to private
on('click', '.js-project-target-owner', function (event) {
  const ownerType = event.currentTarget.getAttribute('data-owner-type')!
  const repoOwner = ownerType === 'repository'
  const projectCloneDialog = event.currentTarget.closest<HTMLElement>('.js-project-clone-dialog')!

  const visibilityOptions = projectCloneDialog.querySelector<HTMLElement>('.js-project-clone-visibility')!
  /* eslint-disable-next-line github/no-d-none */
  visibilityOptions.classList.toggle('d-none', repoOwner)

  if (repoOwner) {
    const privateInput = projectCloneDialog.querySelector<HTMLInputElement>('.js-project-clone-private')!
    privateInput.checked = true
  }
})

// Refresh the project
on('click', '.js-refresh-project', function () {
  window.location.reload()
})

// ****************************************************************************
// Column interactions

remoteProjectForm('.js-create-project-column', async function (form, send) {
  const response = await send.html()
  const html = response.html.querySelector<HTMLElement>('*')!
  const isForm = html.classList.contains('js-column-form-container')

  if (isForm) {
    const container = form.closest<HTMLElement>('.js-column-form-container')!
    container.replaceWith(html)
    loadAutomationOptions()
  } else {
    const newColumnContainer = document.querySelector<HTMLElement>('.js-new-project-column-container')!
    newColumnContainer.before(response.html)
    closeDialog(form)

    addColumnNav(html)
    const columnID = html.getAttribute('data-id')
    if (columnID) {
      setActiveColumn(columnID)
      setColumnHash(columnID)
      scrollActiveColumnNavIntoViewIfNeeded()
    }

    // if this is the first column they've created,
    // remove the large blank slate prompt and replace with the "Add column" widget
    const blankSlate = document.querySelector<HTMLElement>('.js-new-column-blankslate')!
    /* eslint-disable-next-line github/no-d-none */
    if (!blankSlate.classList.contains('d-none')) {
      toggleBlankSlate(false)
    }
  }
})

remoteProjectForm('.js-update-project-column', async function (form, send) {
  const response = await send.html()
  const html = response.html.querySelector<HTMLElement>('*')!
  const isForm = html.classList.contains('js-column-form-container')

  if (isForm) {
    const container = form.closest<HTMLElement>('.js-column-form-container')!
    container.replaceWith(html)
    loadAutomationOptions()
  } else {
    const columnID = form.getAttribute('data-column-id')!
    const column = document.querySelector<HTMLElement>(`.js-project-column[data-id="${columnID}"]`)!
    column.replaceWith(html)
    updateColumnNav(html)
    closeDialog(form)
  }
})

remoteProjectForm('.js-delete-project-column', async function (form, send) {
  await send.text()
  const columnID = form.getAttribute('data-column-id')!

  // before we delete potentially the last column, find them all
  const columns = document.querySelectorAll<HTMLElement>('.js-project-column')
  document.querySelector<HTMLElement>(`.js-project-column[data-id="${columnID}"]`)!.remove()

  closeDialog(form)
  deleteColumnNav(columnID)

  // replace the "add column" prompt with the blank slate state
  // if this was the last column deleted
  if (columns.length === 1) {
    toggleBlankSlate(true)
  }
})

// Archive all cards in a column
remoteProjectForm('.js-archive-project-column', async function (form, send) {
  await send.text()

  const columnID = form.getAttribute('data-column-id')!
  const column = document.querySelector<HTMLElement>(`.js-project-column[data-id="${columnID}"]`)!
  removeAllCardsFromColumn(column)
  closeDialog(form)
})

// Focus cards and columns on page load and match the
// id in the URL hash except when on mobile.
observe('.js-project-column-card, .js-project-column', {
  constructor: HTMLElement,
  initialize(el) {
    if (el.id === document.location.hash.substr(1)) {
      setTimeout(() => {
        if (!document.querySelector('.js-project-page-mobile')) {
          el.focus()
        }
      }, 1)
    }
  },
})

function closeMigrationOverlay() {
  const modal = document.querySelector<ModalDialogElement | HTMLDialogElement>('.js-migrate-project-dialog')
  if (modal) {
    modal.close()
  }
}

function showConfirmationDialog() {
  const detailsElement = document.querySelector<HTMLDetailsElement>('.js-project-migration-override')
  if (detailsElement) {
    detailsElement.open = true
  }
}

on('click', '.js-show-migration-override-button', function () {
  // because the confirmation dialog needs to be visible when the migration modal is hidden
  // we need to handle these events manually and perform the transition
  closeMigrationOverlay()
  showConfirmationDialog()
})

function toggleBlankSlate(visible: boolean) {
  const newColumnContainer = document.querySelector<HTMLElement>('.js-new-project-column-container')!
  const blankSlate = document.querySelector<HTMLElement>('.js-new-column-blankslate')!
  const addButton = newColumnContainer.querySelector<HTMLElement>('.js-new-column-button')!

  /* eslint-disable-next-line github/no-d-none */
  blankSlate.classList.toggle('d-none', !visible)
  /* eslint-disable-next-line github/no-d-none */
  addButton.classList.toggle('d-none', visible)

  // hide the add cards menu in case it is open
  if (visible) {
    togglePaneVisibility('.js-project-triage-pane', false)
  }
}

// ****************************************************************************
// Card interaction
remoteProjectForm('.js-remove-card-after-request', async function (form, send) {
  await send.text()

  removeCard(form.closest<HTMLElement>('.js-project-column-card')!)
  refreshAddCardsSearchResults()
})

remoteProjectForm('.js-archive-project-card', async function (form, send) {
  removeCard(form.closest<HTMLElement>('.js-project-column-card')!)

  await send.text()

  refreshArchivedCardsSearchResults()
})

remoteProjectForm('.js-unarchive-project-card', async function (form, send) {
  const card = form.closest<HTMLElement>('.js-project-column-card')!
  const columnID = card.getAttribute('data-column-id')!
  const columnCards = document.querySelector(`.js-project-column[data-id="${columnID}"] .js-project-column-cards`)

  if (columnCards) {
    columnCards.append(card)
    moveCard(card)
    card.focus()
  }

  send.text()
})

remoteProjectForm('.js-project-archived-cards-search-form', async function (form, send) {
  const container = form.closest<HTMLElement>('.js-project-archived-cards-pane')!
  const searchResults = container.querySelector<HTMLElement>('.js-project-column-cards')!
  searchResults.textContent = 'Loading…'
  form.classList.add('loading')

  let response
  try {
    response = await send.html()
  } catch (fetchError) {
    // TODO show error
  }

  searchResults.textContent = ''

  if (response) {
    searchResults.append(response.html)
  }

  form.classList.remove('loading')
})

hashChange(async function ({target}) {
  if (target instanceof HTMLElement) {
    // There's an element in the DOM whose id matches the current hash. Let's
    // focus on it.
    if (target.matches('.js-project-column-card')) {
      target.focus()
    } else if (target.matches('.js-project-column')) {
      setActiveColumn(target.getAttribute('data-id')!)
      scrollActiveColumnNavIntoViewIfNeeded()
    }
  } else if (document.location.hash.length > 0) {
    // There's no element in the DOM whose id matches the current hash.

    const cardID = getCardIdFromHash()

    if (cardID != null) {
      // It's pointing to a card, so let's see if it's in the archive.
      const archivedCardsPane = document.querySelector('.js-project-archived-cards-pane')

      if (archivedCardsPane) {
        const urlTemplate = archivedCardsPane.getAttribute('data-project-archived-card-url')!

        const response = await fetch(urlTemplate.replace('{card_id}', cardID), {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })

        if (response.ok) {
          const results = await response.text()
          if (results != null) {
            // The card is in the archive, so load it up.
            loadArchivedCardsPane(cardID)
            togglePaneVisibility('.js-project-archived-cards-pane', true)
          }
        }
      }
    }
  }
})

// ****************************************************************************
// Project search pane

function togglePaneVisibility(paneClass: string, visible: boolean) {
  for (const element of document.querySelectorAll(
    '.js-project-header, .js-project-columns, .js-project-pane, .js-project-small-footer',
  )) {
    element.classList.toggle('hide-sm', visible)
  }

  for (const element of document.querySelectorAll('.js-project-columns')) {
    element.classList.toggle('push-board-over', visible)
  }

  for (const pane of document.querySelectorAll(paneClass)) {
    /* eslint-disable-next-line github/no-d-none */
    pane.classList.toggle('d-none', !visible)
    pane.classList.toggle('hide-sm', !visible)
  }
}

function loadActivityPane() {
  const fragment = document.querySelector('.js-project-activity-results')
  if (fragment) {
    const dataFragmentUrl = fragment.getAttribute('data-project-activity-url')!
    const url = new URL(dataFragmentUrl, window.location.origin)
    fragment.setAttribute('src', url.toString())
  }
}

function loadArchivedCardsPane(cardID?: string) {
  const fragment = document.querySelector('.js-project-archived-cards-results')

  if (fragment) {
    const dataFragmentUrl = fragment.getAttribute('data-project-archived-cards-url')!
    const url = new URL(dataFragmentUrl, window.location.origin)
    const searchParams = new URLSearchParams(url.search.slice(1))

    if (cardID) {
      searchParams.append('card_id', cardID)
    }
    url.search = searchParams.toString()

    fragment.setAttribute('src', url.toString())
  }
}

on('click', '.js-show-project-triage', function () {
  togglePaneVisibility('.js-project-pane', false)
  togglePaneVisibility('.js-project-menu-pane', true)
  togglePaneVisibility('.js-project-triage-pane', true)

  const triagePane = document.querySelector<HTMLElement>('.js-project-triage-pane')!
  triagePane.addEventListener(
    'animationend',
    function () {
      triagePane.querySelector<HTMLInputElement>('.js-project-triage-search-text')!.focus()
    },
    {once: true},
  )
})

on('click', '.js-show-archived-cards', function () {
  loadArchivedCardsPane()
  togglePaneVisibility('.js-project-pane', false)
  togglePaneVisibility('.js-project-menu-pane', true)
  togglePaneVisibility('.js-project-archived-cards-pane', true)
})

on('click', '.js-show-project-menu', function () {
  loadActivityPane()
  togglePaneVisibility('.js-project-pane', false)
  togglePaneVisibility('.js-project-menu-pane', true)
})

on('click', '.js-hide-project-menu', function () {
  togglePaneVisibility('.js-project-pane', false)
})

function toggleShowAwaitingTriageIssues(event: Event, show: boolean) {
  document.querySelector<HTMLInputElement>('.js-show-triage-field')!.value = show ? 'show' : ''
  refreshAddCardsSearchResults()
}

on('click', '.js-show-triage', function (event) {
  toggleShowAwaitingTriageIssues(event, true)
})

on('click', '.js-hide-triage', function (event) {
  toggleShowAwaitingTriageIssues(event, false)
})

remoteProjectForm('.js-project-search-form', async function (form, send) {
  if (queryContainsInvalidParam(form)) {
    const checkbox = form.querySelector<HTMLInputElement>('.js-toggle-linked-repo-scope')!
    checkbox.checked = false
  }

  form.classList.add('loading')
  const response = await send.html()
  const container = form.closest<HTMLElement>('.js-project-triage-pane')!
  const searchResults = container.querySelector<HTMLElement>('.js-project-search-results')!
  searchResults.textContent = ''
  searchResults.append(response.html)
  form.classList.remove('loading')
})

function queryContainsInvalidParam(form: Element) {
  const checkbox = form.querySelector('.js-toggle-linked-repo-scope')
  const input = form.querySelector<HTMLInputElement>('.js-project-triage-search-text')!

  // If the search is limited to linked repos *AND*
  // they have entered a `repo:` query
  // it is an invalid search query
  if (checkbox instanceof HTMLInputElement && checkbox.checked) {
    return queryContainsQualifier(input.value, 'repo')
  } else {
    return false
  }
}

remoteProjectForm('.js-project-activity-form', async function (form, send) {
  const response = await send.html()
  const activityPane = form.closest<HTMLElement>('.js-project-activity-pane')!
  const activityResults = activityPane.querySelector('.js-project-activity-container')

  if (activityResults) {
    activityResults.textContent = ''
    activityResults.append(response.html)
  }

  if (activityPane) {
    activityPane.classList.remove('Details--on')
  }
})

// Remove cards from search pane when they get added to a column
observe('.js-project-column-card', function (el: Element) {
  if (el.getAttribute('data-card-id')) {
    const contentType = el.getAttribute('data-content-type')!
    const contentID = el.getAttribute('data-content-id')!
    const searchPaneCard = document.getElementById(`card-${contentType}-${contentID}`)
    if (searchPaneCard) {
      searchPaneCard.remove()
    }
  }
})

// Clean up any cards that may have moved across pages when paginating
// over card search results so duplicate cards are never displayed.
function cleanupDuplicateSearchCards() {
  for (const container of document.querySelectorAll('.js-project-search-cards')) {
    const searchCards = container.querySelectorAll('.js-project-column-card')
    const cardIDs: {[key: string]: boolean} = {}
    for (const card of searchCards) {
      const cardID = card.getAttribute('id')
      if (cardID) {
        if (cardIDs.hasOwnProperty(cardID)) {
          card.remove()
        }
        cardIDs[cardID] = true
      }
    }
  }
}

observe('.js-more-search-cards-form', {
  subscribe: form => fromEvent(form, 'page:loaded', cleanupDuplicateSearchCards),
})

// ****************************************************************************
// Note interactions

function closeNoteForm(target: Element) {
  const noteFormDetails = target.closest('.js-details-container')
  if (noteFormDetails) {
    noteFormDetails.classList.remove('open', 'Details--on')
  }
}

function onNoteFormKeydown(event: Event) {
  if (eventToHotkeyString(event as KeyboardEvent) === 'Escape') {
    closeNoteForm(event.currentTarget as Element)
  }
}

on('click', '.js-dismiss-note-form-button', function (event) {
  closeNoteForm(event.currentTarget)
})

function previewNote(noteInput: HTMLInputElement | HTMLTextAreaElement) {
  const column = noteInput.closest('.js-project-column')
  if (!column) return

  const previewText = noteInput.value
  noteInput.setAttribute('data-preview-text', previewText)

  // Only preview note if it looks like it contains a possible reference
  if (/(gh-|issues\/|pull\/|projects\/|discussions\/|#)\d+/.test(previewText)) {
    const previewForm = column.querySelector<HTMLFormElement>('.js-preview-note-form')!

    ;(previewForm.elements.namedItem('note') as HTMLInputElement).value = previewText
    requestSubmit(previewForm)
  } else {
    column.querySelector<HTMLElement>('.js-note-preview')!.textContent = ''
  }
}

onFocus('.js-note-text', function (el: HTMLElement) {
  const input = el as HTMLTextAreaElement
  addThrottledInputEventListener(input, previewNote)
  input.addEventListener(
    'blur',
    function onBlur() {
      removeThrottledInputEventListener(input, previewNote)
    },
    {once: true},
  )
})

observe('.js-project-note-form', {
  subscribe: el => fromEvent(el, 'keyup', onNoteFormKeydown),
})

remoteProjectForm('.js-preview-note-form', async function (form, wants) {
  const column = form.closest<HTMLElement>('.js-project-column')!
  const noteTextArea = column.querySelector<HTMLTextAreaElement>('.js-note-text')!
  const previewArea = column.querySelector<HTMLElement>('.js-note-preview')!

  let notePreview
  try {
    const response = await wants.html()
    notePreview = response.html
  } catch (responseError) {
    // Ignore and clear preview area
  }

  previewArea.textContent = ''
  if (notePreview && noteTextArea.getAttribute('data-preview-text') === noteTextArea.value) {
    previewArea.appendChild(notePreview)
  }
})

on('tab-container-changed', '.js-project-picker-tabs', async function (event) {
  const container = event.currentTarget
  const tab = event.detail.relatedTarget
  if (!tab) return

  const remoteInput = container.querySelector<RemoteInputElement>('remote-input')!
  remoteInput.setAttribute('aria-owns', tab.id)
  remoteInput.src = tab.getAttribute('data-filter-url')!
})

remoteProjectForm('.js-project-note-form', async function (form, wants) {
  const textarea = form.querySelector<HTMLTextAreaElement>('textarea')!
  textarea.disabled = true

  const button = form.querySelector<HTMLButtonElement>('button')!
  button.disabled = true

  const column = form.closest('.js-project-column')

  if (column) {
    const noteTextArea = column.querySelector<HTMLElement>('.js-note-text')!
    noteTextArea.removeAttribute('data-preview-text')
  }

  let response
  try {
    response = await wants.html()
  } catch (responseError) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    response = responseError.response
    if (column && response && response.status === 409 && response.html) {
      const previewArea = column.querySelector<HTMLElement>('.js-note-preview')!
      previewArea.textContent = ''
      previewArea.appendChild(response.html)
    }

    // The response is not html when the status is 422 and the remoteProjectForm
    // catches a different error and as a result does not show the error message.
    // So, doing it here instead.
    if (response && response.status === 422) {
      await openDialog('project-update-error')
    }

    button.disabled = false
    // look, i know you want to remove this, but you really can't! context:
    // https://github.com/github/github/pull/81537
    // eslint-disable-next-line github/no-blur
    textarea.blur()
    textarea.disabled = false
    textarea.focus()
    return
  }

  if (column) {
    const previewArea = column.querySelector<HTMLElement>('.js-note-preview')!
    previewArea.textContent = ''
    handleCreateNote(column, response.html)
  } else {
    handleUpdateNote(form, response.html)
  }

  // look, i know you want to remove this, but you really can't! context:
  // https://github.com/github/github/pull/81537
  // eslint-disable-next-line github/no-blur
  textarea.blur()
  textarea.disabled = false
  changeValue(textarea, '')
  textarea.focus()
})

function handleCreateNote(column: Element, fragment: DocumentFragment) {
  const cards = column.querySelector<HTMLElement>('.js-project-column-cards')!
  cards.prepend(fragment)
  updateColumnCount(column, 1)
}

function handleUpdateNote(form: HTMLFormElement, fragment: DocumentFragment) {
  const html = fragment.querySelector('*')
  const isForm = html && html.classList.contains('js-note-form-container') ? true : false

  if (isForm) {
    form.closest<HTMLElement>('.js-note-form-container')!.replaceWith(fragment)
  } else {
    const id = form.getAttribute('data-card-id')!
    const card = document.getElementById(`card-${id}`)
    if (card) card.replaceWith(fragment)
    form.reset()
    closeDialog(form)
  }
}

//
// Project note card task list updating

// Enable task list and reset input and checkbox default values so they are no
// longer marked as dirty and the card can still live update.
function resetTaskList(taskListContainer: HTMLElement) {
  enableTaskList(taskListContainer)

  const input = taskListContainer.querySelector<HTMLTextAreaElement>('.js-task-list-field')!
  input.defaultValue = input.value

  for (const checkbox of taskListContainer.querySelectorAll<HTMLInputElement>('.task-list-item-checkbox')) {
    checkbox.defaultChecked = checkbox.checked
  }
}

remoteProjectForm('.js-project-column-card .js-comment-update', async function (form, wants, request) {
  const container = form.closest<HTMLElement>('.js-task-list-container')!
  const card = form.closest<HTMLElement>('.js-project-column-card')!
  const noteVersion = form.getAttribute('data-note-version')!
  request.headers.set('X-Note-Version', noteVersion)

  let response
  try {
    response = await wants.json()
  } catch (responseError) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    response = responseError.response
    if (response && response.status === 422 && response.json.stale) {
      displayUpdateMessage(response.json.message)
      resetTaskList(container)
      updateContent(card)
      return
    } else {
      throw responseError
    }
  }

  form.setAttribute('data-note-version', response.json.note_version)
  const note = response.json.note
  const taskListField = container.querySelector<HTMLTextAreaElement>('.js-task-list-field')!
  taskListField.value = note
  const editNoteTemplate = card.querySelector<HTMLTemplateElement>('.js-edit-note-template')!
  const editFormInput = editNoteTemplate.content.querySelector<HTMLTextAreaElement>('.js-edit-note-input')!
  editFormInput.textContent = note
  resetTaskList(container)
})

//
// Project card filtering

// Debounce by 1ms to avoid filtering a bunch of times on the initial card load.
const debouncedUpdateCardFiltering = debounce(function () {
  updateFilteredCardCounts()
  updateFilterState()
}, 1)

on('click', '.js-card-filter-clear', function () {
  const input = document.querySelector<HTMLInputElement>('.js-card-filter-input')!
  input.value = ''
  fire(input, 'input')
})

on('change', '.js-column-purpose-value', function (event) {
  loadAutomationOptions((event.currentTarget as HTMLInputElement).getAttribute('data-preset-url')!)
})

async function loadAutomationOptions(url?: string) {
  const container = document.querySelector('.js-project-column-automation')
  if (!container) return

  const form = container.closest<HTMLElement>('.js-column-settings-form')!
  const optionsContainer = container.querySelector<IncludeFragmentElement>('.js-project-automation-options')!
  const loader = form.querySelector<HTMLElement>('.js-project-autmoation-loader')!
  const purposeDetails = form.querySelector<HTMLElement>('.js-column-purpose-details')!
  const src = url || container.getAttribute('data-src')!

  // Clear existing options before fetching new ones
  optionsContainer.src = encodeURI(src)
  optionsContainer.addEventListener('loadstart', () => setOptionsLoading(true))
  optionsContainer.addEventListener('loadend', () => setOptionsLoading(false))

  function setOptionsLoading(loading: boolean) {
    purposeDetails.hidden = loading
    loader.hidden = !loading
  }
}

// Load the automation options when the dialog opens
observe('.project-dialog .js-project-column-automation', function () {
  loadAutomationOptions()
})

observe('.js-project-column-card', {
  constructor: HTMLElement,
  add(card) {
    filterCard(card)
    debouncedUpdateCardFiltering()
  },
  remove: debouncedUpdateCardFiltering,
})

observe('.js-card-filter-input', {
  constructor: HTMLInputElement,
  add(el) {
    el.addEventListener('input', debounce(filterCards, 500))

    addThrottledInputEventListener(el, function () {
      updateSuggestionsDisplay(el)
    })
  },
})

on('focusin', '.js-card-filter-input', function (event) {
  if (event.currentTarget instanceof HTMLInputElement) {
    updateSuggestionsDisplay(event.currentTarget)
  }
})

// We do this in a delayed focusout to avoid conflicting with a mouse click on
// an item in the suggestions menu.
on('focusout:delay', '.js-card-filter-input', function () {
  hideSuggestions()
})

on('click', '.js-card-filter-suggestion', function () {
  // Restore focus
  const input = document.querySelector<HTMLInputElement>('.js-card-filter-input')!
  input.focus()
})

on('click', '.js-card-filter', function (event) {
  const filter = event.currentTarget.getAttribute('data-card-filter')
  if (filter) {
    applyFilter(filter)
  }
})

on('navigation:keydown', '.js-card-filter-suggester', function (event) {
  switch (eventToHotkeyString(event.detail.originalEvent)) {
    case 'Escape':
      // The default behavior when hitting esc in an input is to clear the
      // input field. We prevent that behavior so they can hide the menu with
      // esc.
      event.preventDefault()
      hideSuggestions()
      break
    case 'ArrowLeft':
    case 'ArrowRight':
    case 'Enter':
      hideSuggestions()
      break
  }
})

on('navigation:open', '.js-card-filter-suggester', function (event) {
  const inputField = document.querySelector('.js-card-filter-input')

  if (inputField instanceof HTMLInputElement) {
    const suggestionsContainer = document.querySelector<HTMLElement>('.js-card-filter-suggester')!
    const suggestedText = (event.target as Element).getAttribute('data-value')!
    const textBeforeCursor = inputField.value.slice(0, inputField.selectionEnd!).replace(/\S+$/, '')
    const textAfterCursor = inputField.value.slice(inputField.selectionEnd!)

    /* eslint-disable-next-line github/no-d-none */
    if (!suggestionsContainer.classList.contains('d-none')) {
      event.preventDefault()
      inputField.value = textBeforeCursor + suggestedText + textAfterCursor
      fire(inputField, 'input')
      updateSuggestionsDisplay(inputField)
    }

    hideSuggestions()
  }
})

observe('.js-project-columns-container', {
  subscribe: el =>
    fromEvent(el, 'socket:message', async function (event: Event) {
      const data = (event as CustomEvent).detail.data
      if (clientUID !== data.client_uid) {
        const container = event.currentTarget as HTMLElement
        refreshAddCardsSearchResults()
        refreshArchivedCardsSearchResults()
        await verifySsoSession()
        updateProject(container, data)
      } else if (data.action === 'column_automation_updated' && data.state != null) {
        // Update just the footers on these events even from the current user
        // since adding automation to one column may involve removing it from
        // another column
        updateProjectColumnAutomation(data.state)
      }
    }),
})

document.addEventListener(
  'socket:message',
  function (event: Event) {
    const {data, name} = (event as CustomEvent).detail

    if (data.locked) {
      closeLockContainer()
    }

    if (data.is_project_activity) {
      const activityPane = document.querySelector('.js-project-activity-pane')
      activityPane?.classList.add('Details--on')
    }

    if (name.startsWith('projects:metadata:')) {
      document.title = data.name

      if (data.project_migration) {
        const migrateMenuItem = document.querySelector<HTMLButtonElement>('.js-migrate-menu-item')
        if (migrateMenuItem) {
          // set the received text content of the menu item to the value returned from the backend
          migrateMenuItem.textContent = data.project_migration.message
          migrateMenuItem.setAttribute('disabled', 'disabled')
        }
      }
    }
  },
  {capture: true},
)

// ****************************************************************************
// Drag and drop setup

function fallbackClassForElement(el: HTMLElement): string | undefined {
  // Set position fixed on mobile so drag element shows up in correct location.
  // sortablejs sets this value as well but not with !important and this element
  // is already position-relative which is !important
  return el.matches('.js-drag-by-handle') ? 'position-fixed' : undefined
}

function handleForElement(el: HTMLElement): string | undefined {
  // Only allow dragging from the drag handle on mobile so scrolling vs.
  // dragging can be differentiated when the cards and columns are touched
  return el.matches('.js-drag-by-handle') ? '.js-project-dragger' : undefined
}

observe('.js-project-columns-drag-container', {
  constructor: HTMLElement,
  async add(el) {
    const {Sortable} = await import('./github/sortable-behavior')
    Sortable.create(el, {
      animation: 150,
      draggable: '.js-project-column',
      filter: '.js-note-text, .js-redacted-project-column-card',
      preventOnFilter: false,
      fallbackClass: fallbackClassForElement(el),
      handle: handleForElement(el),
      handleReplacedDragElement: true,
      group: {
        name: 'project-column',
        put: false,
        pull: false,
      },
      onMove: handleColumnMove,
      onUpdate: handleColumnUpdate,
    })
  },
})

observe('.js-card-drag-container', {
  constructor: HTMLElement,
  async add(el) {
    const {Sortable} = await import('./github/sortable-behavior')
    Sortable.create(el, {
      animation: 150,
      draggable: '.js-project-column-card',
      filter: '.js-redacted-project-column-card',
      preventOnFilter: false,
      fallbackClass: fallbackClassForElement(el),
      fallbackOnBody: el.matches('.js-drag-by-handle'),
      handle: handleForElement(el),
      handleReplacedDragElement: true,
      group: 'project-card',
      onAdd: handleCardDrag,
      onUpdate: handleCardDrag,
    })
  },
})

observe('.js-project-search-results-drag-container', {
  constructor: HTMLElement,
  async add(el) {
    const {Sortable} = await import('./github/sortable-behavior')
    Sortable.create(el, {
      sort: false,
      animation: 150,
      draggable: '.js-project-column-card',
      fallbackClass: fallbackClassForElement(el),
      fallbackOnBody: el.matches('.js-drag-by-handle'),
      handle: handleForElement(el),
      group: {
        name: 'project-card',
        put: false,
        pull: true,
      },
      onAdd: handleCardDrag,
      onUpdate: handleCardDrag,
    })
  },
})

// Remove cards from search pane when they get added to a column
observe('.js-project-column-card', function (el: Element) {
  if (el.getAttribute('data-card-id')) {
    const contentType = el.getAttribute('data-content-type')!
    const contentID = el.getAttribute('data-content-id')!
    const searchPaneCard = document.getElementById(`card-${contentType}-${contentID}`)
    if (searchPaneCard) {
      searchPaneCard.remove()
    }
  }
})

observe('.js-client-uid-field', {
  constructor: HTMLInputElement,
  add(el) {
    el.value = clientUID
  },
})

// Set the initial active column to display using the column ID in the URL hash
// falling back to the first column on the board
function setInitialActiveColumn() {
  let column
  if (document.location.hash.startsWith('#column-')) {
    column = document.getElementById(document.location.hash.substr(1))
  }
  // Default to first column when no column id in hash
  if (!column) {
    column = document.querySelector('.js-project-column')
  }
  if (column) {
    const columnID = column.getAttribute('data-id')
    if (columnID) {
      setActiveColumn(columnID)
      scrollActiveColumnNavIntoViewIfNeeded()
    }
  }
}

// Ensure there is always an active column by listening for the active column
// being removed and selecting a new one.
observe('.js-project-column[data-active-column]', {
  remove() {
    if (!document.querySelector('.js-project-column[data-active-column]')) {
      setInitialActiveColumn()
    }
  },
})

// Set the column hash in the URL to the given column ID
function setColumnHash(columnID: string) {
  // Only put column in hash on mobile devices
  if (!document.querySelector('.js-project-page-mobile')) return

  const anchor = document.createElement('a')
  anchor.href = encodeURI(`#column-${columnID}`)
  replaceState(null, '', anchor.href)
}

// Set the active column displayed to the given column ID
function setActiveColumn(columnID: string) {
  for (const column of document.querySelectorAll('.js-project-column')) {
    column.classList.toggle('hide-sm', columnID !== column.getAttribute('data-id'))
    if (columnID === column.getAttribute('data-id')) {
      column.setAttribute('data-active-column', '')
    } else {
      column.removeAttribute('data-active-column')
    }
  }

  for (const nav of document.querySelectorAll('.js-project-column-navigation-item')) {
    nav.classList.toggle('selected', columnID === nav.getAttribute('data-column-id'))
  }
}

// Scroll the nav for the active column into view
function scrollActiveColumnNavIntoViewIfNeeded() {
  const activeColumn = document.querySelector<HTMLElement>('.js-project-column-navigation-item.selected')
  if (!activeColumn) return

  const nav = activeColumn.closest<HTMLElement>('.js-project-column-nav')!
  const activeColumnLeft = activeColumn.offsetLeft
  const activeColumnWidth = activeColumn.offsetWidth
  const activeColumnRight = activeColumnLeft + activeColumnWidth
  const windowWidth = window.innerWidth
  // Check if active column is offscreen either on the left or the right
  if (activeColumnRight - nav.scrollLeft > windowWidth || activeColumnRight < nav.scrollLeft) {
    // Center column nav on screen
    nav.scrollLeft = activeColumnLeft - windowWidth / 2 + activeColumnWidth / 2
  }
}

// Change the active column when the column navigation is clicked
on('click', '.js-project-column-navigation-item', function (event) {
  event.preventDefault()
  const columnID = event.currentTarget.getAttribute('data-column-id')!
  setActiveColumn(columnID)
  setColumnHash(columnID)
})

// Open a dialog for the active column
on('click', '.js-open-active-column-dialog', function (event) {
  const column = document.querySelector('.js-project-column[data-active-column]')
  if (column) {
    const columnID = column.getAttribute('data-id')!
    const prefix = event.currentTarget.getAttribute('data-dialog-prefix')!
    openDialog(`${prefix}-${columnID}`)
  }
})

// Toggle card/column edit mode that enables reordering and moving cards and
// columns on phones and tablets.
on('click', '.js-toggle-project-edit-mode', function (event) {
  event.currentTarget.classList.toggle('selected')
  document.body.classList.toggle('project-edit-mode')
})

// Build a dialog to display all the columns that the selected card can be
// moved too
function populateMoveCardDialog(moveCardDialog: Element, button: Element): void {
  const container = moveCardDialog.querySelector<HTMLElement>('.js-move-card-to-column-dialog-body')!
  const card = button.closest<HTMLElement>('.js-project-column-card')!
  const currentColumn = card.closest('.js-project-column')
  const currentColumnID = currentColumn && currentColumn.getAttribute('data-id')
  const template = document.querySelector<HTMLTemplateElement>('#move-card-to-column-button-template')!
  const title = moveCardDialog.querySelector<HTMLElement>('.js-move-card-to-column-title')!

  title.textContent = card.closest('.js-project-archived-cards-pane')
    ? title.getAttribute('data-restore-title')!
    : title.getAttribute('data-move-title')!

  container.textContent = ''
  for (const column of document.querySelectorAll('.js-project-column-navigation-item')) {
    const columnID = column.getAttribute('data-column-id')!
    const columnButton = (template.content.cloneNode(true) as DocumentFragment).querySelector<HTMLButtonElement>(
      '.js-move-card-to-column-button',
    )!
    columnButton.setAttribute('data-card-id', card.getAttribute('data-card-id')!)
    columnButton.setAttribute('data-content-id', card.getAttribute('data-content-id')!)
    columnButton.setAttribute('data-content-type', card.getAttribute('data-content-type')!)
    columnButton.setAttribute('data-column-id', columnID)
    if (button.hasAttribute('data-change-active-column')) {
      columnButton.setAttribute('data-change-active-column', '')
    }
    columnButton.textContent = column.getAttribute('data-column-name')!
    columnButton.disabled = currentColumnID != null && columnID === currentColumnID
    container.appendChild(columnButton)
  }
}

// Move a card to the column selected from the dialog
on('click', '.js-move-card-to-column-button', function (event) {
  const cardID = event.currentTarget.getAttribute('data-card-id')!
  const contentID = event.currentTarget.getAttribute('data-content-id')!
  const contentType = event.currentTarget.getAttribute('data-content-type')!
  const columnID = event.currentTarget.getAttribute('data-column-id')!

  const card = findCard(cardID, contentID, contentType)
  const column = document.querySelector(`.js-project-column[data-id="${columnID}"] .js-project-column-cards`)
  if (card && column) {
    if (event.currentTarget.hasAttribute('data-change-active-column')) {
      setActiveColumn(columnID)
      setColumnHash(columnID)
      scrollActiveColumnNavIntoViewIfNeeded()
    }
    const fromColumn = card.closest('.js-project-column')
    column.prepend(card)
    moveCard(card, fromColumn)
  }
})

// Toggle add note form in active column when clicked from the small menu
on('click', '.js-add-note-button', function (event) {
  event.preventDefault()
  const addNoteTarget = document.querySelector<HTMLElement>(
    '.js-project-column[data-active-column] .js-add-note-container .js-details-target',
  )
  if (addNoteTarget) {
    toggleDetailsTarget(addNoteTarget)
  }
})

on('click', '.js-project-lock-info-button, .js-close-project-lock-popover', toggleLockPopover)

function closeLockContainer() {
  const container = document.querySelector('.js-project-lock-container')
  if (!container) return
  const popover = container.querySelector<HTMLElement>('.Popover')!
  /* eslint-disable-next-line github/no-d-none */
  const hidden = popover.classList.contains('d-none')
  /* eslint-disable-next-line github/no-d-none */
  popover.classList.toggle('d-none', true)
  if (!hidden) {
    const activeElement = document.activeElement
    if (container.contains(activeElement)) {
      const header = document.querySelector<HTMLElement>('.js-project-header')
      if (header) {
        header.focus()
      }
    }
  }
}

function toggleLockPopover() {
  const popover = document.querySelector<HTMLElement>('.js-project-lock-container .Popover')!
  /* eslint-disable-next-line github/no-d-none */
  const hidden = popover.classList.contains('d-none')
  /* eslint-disable-next-line github/no-d-none */
  popover.classList.toggle('d-none', !hidden)
}

// Update the back to link shown in the header of the content details sidebar
function updateContentDetailsBackToLink(cardDetailsPane: Element, fromPane: Element) {
  const template = cardDetailsPane.querySelector<HTMLTemplateElement>('.js-project-card-details-back-template')!
  const header = template.content.cloneNode(true) as DocumentFragment
  const title = fromPane.getAttribute('data-back-to-label')!
  const backClass = fromPane.getAttribute('data-back-to-class')!

  header.querySelector<HTMLElement>('.js-project-card-details-back-to-title')!.textContent = title
  header.querySelector<HTMLElement>('.js-project-card-details-back-link')!.classList.add(backClass)

  const container = cardDetailsPane.querySelector<HTMLElement>('.js-project-card-details-back-container')!
  container.textContent = ''
  container.appendChild(header)
}

// Collapse the issue body section of card details if it is too long
function truncateCardDetails() {
  const wrapper = document.querySelector<HTMLElement>('.js-project-issue-body-wrapper')!
  const container = document.querySelector<HTMLElement>('.js-project-issue-body-container')!

  const wrapperHeight = wrapper.getBoundingClientRect().height
  const containerHeight = container.getBoundingClientRect().height
  const needsTruncation = containerHeight > wrapperHeight

  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-project-issue-body-blur')!.classList.toggle('d-none', !needsTruncation)
  /* eslint-disable-next-line github/no-d-none */
  document
    .querySelector<HTMLElement>('.js-project-issue-body-details-target')!
    .classList.toggle('d-none', !needsTruncation)
}

// Show error UI when issue details fails to load
function handleIssueDetailsLoadError(cardDetailsContainer: Element, issueLink: Element) {
  /* eslint-disable-next-line github/no-d-none */
  cardDetailsContainer.querySelector<HTMLElement>('.js-project-card-details-error')!.classList.remove('d-none')
  /* eslint-disable-next-line github/no-d-none */
  cardDetailsContainer.querySelector<HTMLElement>('.js-project-card-details-loader')!.classList.add('d-none')
  cardDetailsContainer.querySelector<HTMLElement>('.js-project-card-details-retry')!.addEventListener(
    'click',
    function () {
      // Clear cached URL and reload
      cardDetailsContainer.removeAttribute('data-issue-url')
      showIssueDetails(issueLink, false)
    },
    {once: true},
  )
}

// Create the loading UI shown when loading the details for an issue
function createContentDetailsLoader(issueLink: Element, openedFromOtherPane: boolean) {
  const issueDetailsContainer = issueLink.closest<HTMLElement>('.js-project-issue-details-container')!
  const loaderTemplate = document.querySelector<HTMLTemplateElement>('.js-project-card-details-loader-template')!
  const loader = loaderTemplate.content.cloneNode(true) as DocumentFragment

  const isOpen = issueDetailsContainer.querySelector<SVGElement>('.js-issue-octicon')!.classList.contains('open')
  loader.querySelector<HTMLElement>('.js-issue-title')!.textContent = issueLink.textContent
  loader.querySelector<HTMLElement>('.js-issue-number')!.textContent =
    issueDetailsContainer.querySelector<HTMLElement>('.js-issue-number')!.textContent
  loader.querySelector<HTMLElement>('.js-issue-state')!.textContent = isOpen ? 'Close' : 'Reopen'
  const contentLabel = issueLink.getAttribute('data-content-label')!
  for (const issueType of loader.querySelectorAll('.js-issue-type')) {
    issueType.textContent = contentLabel
  }

  for (const externalLink of loader.querySelectorAll('.js-project-card-details-external-link')) {
    externalLink.setAttribute('href', issueLink.getAttribute('href') || '#')
    // eslint-disable-next-line i18n-text/no-en
    externalLink.setAttribute('data-ga-click', `Project board, go to ${contentLabel}, location:sidebar`)
  }

  /* eslint-disable-next-line github/no-d-none */
  loader.querySelector<HTMLElement>('.js-hide-project-menu')!.classList.toggle('d-none', openedFromOtherPane)
  const repoLink = loader.querySelector<HTMLElement>('.js-issue-repository')!
  repoLink.textContent = issueLink.getAttribute('data-content-repo-label')!
  const repoEncodedUrl = encodeURI(issueLink.getAttribute('data-content-repo-url') || '#')
  repoLink.setAttribute('href', repoEncodedUrl)
  const octicon = loader.querySelector<HTMLElement>('.js-project-card-details-loader-octicon')!
  octicon.textContent = ''
  octicon.appendChild(issueDetailsContainer.querySelector<Element>('.js-issue-octicon')!.cloneNode(true))

  return loader
}

// Show the details for the given issue link in the sidebar pane.
async function showIssueDetails(issueLink: Element, shiftFocus: boolean) {
  // TODO Replace with query once this element is always rendered
  const cardDetailsPane = document.querySelector('.js-project-card-details-pane')
  if (!cardDetailsPane) {
    return false
  }

  const issueURL = issueLink.getAttribute('href')!
  const cardDetailsContainer = cardDetailsPane.querySelector<HTMLElement>('.js-project-card-details')!
  const fromPane = issueLink.closest('.js-project-pane')
  const openedFromOtherPane = fromPane != null

  togglePaneVisibility('.js-project-card-details-pane', true)
  /* eslint-disable-next-line github/no-d-none */
  cardDetailsPane
    .querySelector<HTMLElement>('.js-project-card-details-header')!
    .classList.toggle('d-none', !openedFromOtherPane)
  if (fromPane) {
    updateContentDetailsBackToLink(cardDetailsPane, fromPane)
  }

  // Don't reload if the details for this content are already showing
  if (issueURL === cardDetailsContainer.getAttribute('data-issue-url')) {
    if (shiftFocus) {
      focusCardDetailsTitle()
    }
    return
  }

  const loader = createContentDetailsLoader(issueLink, openedFromOtherPane)
  cardDetailsContainer.textContent = ''
  cardDetailsContainer.appendChild(loader)
  cardDetailsContainer.setAttribute('data-issue-url', issueURL)

  let html
  try {
    html = await fetchSafeDocumentFragment(document, `${issueURL}/show_from_project`)
  } catch (fetchError) {
    handleIssueDetailsLoadError(cardDetailsContainer, issueLink)
    return
  }

  if (cardDetailsContainer.getAttribute('data-issue-url') === issueURL) {
    // Hide close menu in details if shown in header that links back to previous pane
    const hideMenu = html.querySelector('.js-hide-project-menu')
    if (hideMenu) {
      /* eslint-disable-next-line github/no-d-none */
      hideMenu.classList.toggle('d-none', openedFromOtherPane)
    }

    cardDetailsContainer.textContent = ''
    cardDetailsContainer.appendChild(html)
    truncateCardDetails()

    if (shiftFocus) {
      focusCardDetailsTitle()
    }
  }
}

function focusCardDetailsTitle() {
  const cardDetailsPane = document.querySelector('.js-project-card-details-pane')
  if (!cardDetailsPane) {
    return
  }

  const externalLink = cardDetailsPane.querySelector<HTMLElement>('.js-project-card-details-external-link')!
  externalLink.focus()
}

// Truncate card details whenever the issue description is updated in the sidebar
afterRemote(function (form) {
  if (form.matches('.js-project-card-details-pane .js-comment .js-comment-update')) {
    truncateCardDetails()
  }
})

on('click', '.js-hide-project-card-details', function () {
  // Clear cached content id so pane reloads on next open
  const cardDetailsContainer = document.querySelector<HTMLElement>('.js-project-card-details')!
  cardDetailsContainer.removeAttribute('data-issue-url')
  cardDetailsContainer.textContent = ''
})

function onCardKeydown(event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  if (event.key !== 'd') {
    return
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */

  const card = event.currentTarget as Element
  const links = card.querySelectorAll('.js-project-card-issue-link')
  // Only handle pressing d key with card selected if card only contains
  // a single issue link since cards may contain multiple issue references.
  if (links.length === 1) {
    showIssueDetails(links[0]!, true)
    event.preventDefault()
  }
}

onFocus('.js-project-column-card', function (card) {
  card.addEventListener('keydown', onCardKeydown)
  card.addEventListener(
    'blur',
    function () {
      card.removeEventListener('keydown', onCardKeydown)
    },
    {once: true},
  )
})

// Should issue link clicks be overridden to open in the sidebar instead?
function overrideCardLinkClick(event: MouseEvent): boolean {
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  // Don't override clicks with special buttons also pressed
  if (event.metaKey) return false
  if (event.ctrlKey) return false
  if (event.altKey) return false
  if (event.shiftKey) return false
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */

  // Only handle left button clicks
  if (event.button !== 0) return false
  return true
}

on('click', '.js-project-card-issue-link', function (event) {
  if (overrideCardLinkClick(event)) {
    showIssueDetails(event.currentTarget, false)
    event.preventDefault()
  }
})

remoteProjectForm('.js-project-card-issue-details-title', async function (form, send) {
  const container = form.closest<HTMLElement>('.js-details-container')!
  const response = await send.json()

  // Close the edit title UI
  toggleDetailsTarget(container)

  // Update the issue title displayed to the new value
  if (response.json.issue_title != null) {
    container.querySelector<HTMLElement>('.js-issue-title')!.textContent = response.json.issue_title
  }

  // Reset the default values on all form elements to their updated values
  for (const field of form.elements) {
    if (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement) {
      field.defaultValue = field.value
    }
  }
})

// ****************************************************************************
// Project create

function checkLinkedReposCount() {
  const repoList = document.querySelector<HTMLElement>('.js-project-create-linked-repo-list')!
  const repoListBlankslate = repoList.querySelector<HTMLElement>('.js-blankslate')!
  const linkRepoSearchInputGroup = document.querySelector<HTMLElement>(
    '.js-project-create-linked-repo-search-input-group',
  )!
  const linkedReposMaxMessage = document.querySelector<HTMLElement>('.js-project-create-linked-repo-max')!
  const linkedReposCount = repoList.querySelectorAll('.js-project-repo-link-badge').length
  const maxLinkedReposCount = parseInt(linkedReposMaxMessage.getAttribute('data-max')!, 10)
  const maxLinkedReposReached = linkedReposCount >= maxLinkedReposCount

  /* eslint-disable-next-line github/no-d-none */
  linkRepoSearchInputGroup.classList.toggle('d-none', maxLinkedReposReached)
  /* eslint-disable-next-line github/no-d-none */
  linkedReposMaxMessage.classList.toggle('d-none', !maxLinkedReposReached)
  /* eslint-disable-next-line github/no-d-none */
  repoListBlankslate.classList.toggle('d-none', linkedReposCount > 0)
}

// Add a selected repo to the list and clear the search box
on('auto-complete-change', '.js-project-create-repo-link-auto-complete', function (event) {
  const autoComplete = event.target as AutocompleteElement
  const linkedRepoBadge = autoComplete.querySelector(
    `.js-project-repo-link-badge[data-repo-name="${autoComplete.value}"]`,
  )
  if (!linkedRepoBadge) {
    return
  }

  const repoList = document.querySelector<HTMLElement>('.js-project-create-linked-repo-list')!
  const repoIdInput = linkedRepoBadge.querySelector<HTMLInputElement>('.js-repo-id')!
  let repoAlreadyLinked = false

  // Check for duplicate repos
  for (const input of repoList.querySelectorAll<HTMLInputElement>('.js-repo-id')) {
    if (input.value === repoIdInput.value) {
      repoAlreadyLinked = true
      break
    }
  }

  if (!repoAlreadyLinked) {
    repoIdInput.disabled = false
    repoList.append(linkedRepoBadge)
    /* eslint-disable-next-line github/no-d-none */
    linkedRepoBadge.classList.remove('d-none')
  }

  autoComplete.value = ''

  checkLinkedReposCount()
})

// Remove a selected repo from the list
on('click', '.js-remove-project-repo-link', function (event) {
  const badge = (event.target as Element).closest<HTMLElement>('.js-project-repo-link-badge')!
  badge.remove()
  checkLinkedReposCount()
})

// Don't let users accidentally submit the form when you hit enter
onKey('keydown', '.js-project-create-linked-repo-search', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  if (event.key === 'Enter') {
    event.preventDefault()
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

// Display the project migration dialog to the user
//
// This is necessary because classic projects have their own dialog infrastructure that
// we cannot reimplement in Primer components, so we need to intercept and manually
// display this new dialog
function openMigrateDialog() {
  const modal = document.querySelector<ModalDialogElement | HTMLDialogElement>('.js-migrate-project-dialog')
  if (modal instanceof HTMLDialogElement) {
    modal.showModal()
  } else if (modal) {
    modal.show()
  }
}

async function openMigrateDialogOnLoad() {
  await loaded

  const params = new URLSearchParams(location.search)
  if (params.get('migrate')?.toLowerCase() !== 'confirm') {
    return
  }

  params.delete('migrate')
  history.replaceState(null, '', location.pathname)

  openMigrateDialog()
}

// Open the dialog associated with the clicked button
on('click', '.js-project-dialog-button', function ({currentTarget}) {
  const dialogID = currentTarget.getAttribute('data-dialog-id')!

  if (dialogID === 'migrate-project') {
    // handle the new menu item separate from others, as this dialog
    // is not implemented in the same way
    openMigrateDialog()
    return
  }

  openDialog(dialogID, currentTarget)
})

// Handle the click event for the "Start migration" button and
// open the new migration dialog
on('click', '.js-project-migrate-banner-button', function ({currentTarget}) {
  const dialogID = currentTarget.getAttribute('data-dialog-id')!

  if (dialogID === 'migrate-project') {
    openMigrateDialog()
  }
})

on('click', '.js-project-card-closing-issue-summary', function ({currentTarget}) {
  const card = currentTarget.closest<HTMLElement>('.js-project-column-card')!
  card.focus()
})

// Open the dialog scoped to the active column
on('click', '.js-project-active-column-dialog-button', async function ({currentTarget}) {
  const dialogID = currentTarget.getAttribute('data-dialog-id')!
  const moveCardDialog = await openDialog(dialogID, currentTarget)
  populateMoveCardDialog(moveCardDialog, currentTarget)
})

// TODO: details-menu selected text outside of menu sync
on(
  'details-menu-select',
  '.js-project-change-state-sync-menu',
  function (event) {
    const target = event.detail.relatedTarget as Element
    const container = target.closest<HTMLElement>('.js-project-change-state-sync-menu-parent')!
    const selectedItem = target.querySelector<HTMLElement>('[data-menu-button-text]')!.textContent!.trim()
    container.querySelector<HTMLElement>('.js-project-change-state-sync-menu-button')!.textContent = selectedItem
  },
  {capture: true},
)

on('click', '.js-project-card-closing-issue-summary', function ({currentTarget}) {
  const parent = currentTarget.closest<HTMLElement>('.Details-element')!
  const expand = parent.hasAttribute('open') === false

  const hmacAttribute = expand ? 'data-linked-pr-hmac-expanded' : 'data-linked-pr-hmac-collapsed'
  const hydroEventHmac = currentTarget.getAttribute(hmacAttribute) || ''

  const payloadAttribute = expand ? 'data-linked-pr-expanded' : 'data-linked-pr-collapsed'
  const hydroEventPayload = currentTarget.getAttribute(payloadAttribute) || ''

  const stat = {hydroEventPayload, hydroEventHmac, hydroClientContext: ''}
  sendStats(stat, true)
})

enableKeyboardMovements(moveCard, moveColumn)
setInitialActiveColumn()
openMigrateDialogOnLoad()
