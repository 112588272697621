import {isShortcutAllowed} from '@github-ui/hotkey/keyboard-shortcuts-helper'
import {onFocus} from '../onfocus'

// TODO: Refactor to use data-hotkey
/* eslint eslint-comments/no-use: off */
/* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */

type KeyboardMoveOptions = {keyboardMove?: boolean}
type State = {columns: Array<{id: number; card_ids: number[]}>}

const useCtrlKeyAsMeta = !navigator.userAgent.match(/Macintosh/)

// Return action to perform or original key
function getAction(key: string): string {
  switch (key) {
    case ' ':
      return 'Space'
    case 'ArrowUp':
    case 'k':
    case 'K':
      return 'up'
    case 'ArrowDown':
    case 'j':
    case 'J':
      return 'down'
    case 'ArrowLeft':
    case 'h':
    case 'H':
      return 'left'
    case 'ArrowRight':
    case 'l':
    case 'L':
      return 'right'
    case 'Enter':
      return 'confirm'
    case 'Escape':
      return 'cancel'
    default:
      return key
  }
}

// Base class for column and card moves
class ItemMove {
  item: HTMLElement
  onDone: () => void
  blurListener: (event: FocusEvent) => void
  moved: boolean
  moveInProgress: boolean
  blurring: boolean

  constructor(item: HTMLElement, onDone: () => void) {
    this.item = item
    this.onDone = onDone
    this.moved = false
    this.moveInProgress = false
    this.blurring = false

    this.blurListener = this.handleBlur.bind(this)
    this.item.addEventListener('blur', this.blurListener)

    this.item.classList.add('moving')
  }

  handleBlur(event: FocusEvent) {
    if (!this.moveInProgress && event.relatedTarget) {
      this.blurring = true
      this.cancel()
    }
  }

  isMetaKeyPressed(event: KeyboardEvent) {
    return useCtrlKeyAsMeta ? event.ctrlKey : event.metaKey
  }

  // Is this item in the pending column?
  inPendingColumn(): boolean {
    return this.item.closest('.js-project-pending-cards') != null
  }

  // Find a column by its ID
  findColumn(columnID: string): HTMLElement | null {
    return columnID ? document.querySelector<HTMLElement>(`.js-project-column[data-id="${columnID}"]`) : null
  }

  // Find a card by its ID
  findCard(cardID: string): HTMLElement | null {
    if (!cardID) return null
    let card = document.querySelector<HTMLElement>(`.js-project-column-card[data-card-id="${cardID}"]`)
    if (!card) {
      card = document.querySelector<HTMLElement>(`.js-project-column-card[data-content-id="${cardID}"]`)
    }
    return card
  }

  // Is the given element a column?
  isColumn(column: Element | null): boolean {
    return column != null ? column.classList.contains('js-project-column') : false
  }

  // Get the column the item is in
  getColumn(): Element | null {
    return this.item.closest('.js-project-column')
  }

  // Get the pane the item is in
  getPane(): Element | null {
    return this.item.closest('.js-project-pane')
  }

  // Ge the name of the column the item is in
  getColumnName(): string {
    const column = this.getColumn()
    const label = column && column.querySelector('.js-project-column-name')
    return label ? label.textContent! : ''
  }

  // Get the project columns container
  getColumnsContainer(): HTMLElement {
    return document.querySelector<HTMLElement>('.js-project-columns-container')!
  }

  // Get the column to the right of the current column
  getNextColumn(): Element | null {
    const column = this.getColumn()
    if (column) {
      const nextColumn = column.nextElementSibling
      if (this.isColumn(nextColumn)) {
        return nextColumn
      }
    }
    return null
  }

  // Get the column to the left of the current column
  getPreviousColumn(): Element | null {
    const column = this.getColumn()
    if (column) {
      const previousColumn = column.previousElementSibling
      if (this.isColumn(previousColumn)) {
        return previousColumn
      }
    }
    return null
  }

  // Get the first column
  getFirstColumn(): HTMLElement | null {
    return this.getColumnsContainer().querySelector('.js-project-column')
  }

  // Get the last column
  getLastColumn(): HTMLElement | undefined {
    const columns = this.getColumnsContainer().querySelectorAll<HTMLElement>('.js-project-column')
    return columns[columns.length - 1]
  }

  // Get the ID for the given card
  getCardID(card?: Element | null): string {
    if (!card) return ''
    return card.getAttribute('data-card-id') || card.getAttribute('data-content-id') || ''
  }

  // Get the ID for the given column
  getColumnID(column?: Element | null): string {
    if (!column) return ''
    return column.getAttribute('data-id') || ''
  }

  // Is the specified item actively being moved?
  isMoving(item?: HTMLElement) {
    return item === this.item
  }

  // Update the aria-label for this item
  updateAriaLabel() {
    // This needs to be implemented as a empty function so a sub class can run a cancel callback in the constructor.
  }

  // Move the item using the given function, does state tracking and
  // re-focusing
  moveTransaction(mover: (el: HTMLElement) => void) {
    try {
      this.moveInProgress = true
      mover(this.item)
      if (!this.blurring) {
        this.updateAriaLabel()
        this.item.focus()
      }
    } finally {
      this.moved = true
      this.moveInProgress = false
    }
  }

  // The move has either completed or was canceled
  done() {
    this.item.removeEventListener('blur', this.blurListener)
    this.item.classList.remove('moving')
    this.item.removeAttribute('aria-label')
    this.onDone()
  }

  cancel() {
    // This needs to be implemented as a empty function so a sub class can run a cancel callback in the constructor.
  }
}

// Re-order or move a card between columns using the keyboard
class CardMove extends ItemMove {
  columnID: string
  previousCardID: string
  moveCard: (el: HTMLElement, el2?: HTMLElement | null, opts?: KeyboardMoveOptions) => void
  keydownListener: (event: KeyboardEvent) => void
  updateStartListener: (event: Event) => void
  updateEndListener: () => void
  pendingCard: boolean
  paneColumnCards?: Element | null
  updateSnapshot?: {columnID: string; indexInColumn: number} | null

  constructor(
    card: HTMLElement,
    moveCard: (el: HTMLElement, el2?: HTMLElement | null, opts?: KeyboardMoveOptions) => void,
    onDone: () => void,
  ) {
    super(card, onDone)
    this.moveCard = moveCard
    this.pendingCard = this.inPendingColumn()
    if (this.getPane()) {
      this.paneColumnCards = card.closest('.js-project-column-cards')
    }
    this.columnID = this.getColumnID(this.getColumn())
    this.previousCardID = this.getCardID(card.previousElementSibling)

    this.keydownListener = this.handleKeydown.bind(this)
    this.item.addEventListener('keydown', this.keydownListener)

    this.updateStartListener = this.handleUpdateStart.bind(this)
    this.getColumnsContainer().addEventListener('will-update-project', this.updateStartListener)
    this.updateEndListener = this.handleUpdateEnd.bind(this)
    this.getColumnsContainer().addEventListener('update-project', this.updateEndListener)
  }

  override updateAriaLabel() {
    const index = this.getCardIndex()
    const indexLabel = index >= 0 ? `to position ${index + 1} of ${this.getCardCount()}` : ''
    const columnLabel = `in ${this.getColumnName()} column`
    this.item.setAttribute('aria-label', `Moved card ${indexLabel} ${columnLabel}`.trim())
  }

  handleUpdateStart(event: Event) {
    const column = this.getColumn()
    // Card is no longer on the DOM
    if (!column) {
      this.done()
      return
    }

    // Card is no longer on the board
    if (!this.paneColumnCards && !this.updatePreviousPosition((event as CustomEvent).detail)) {
      this.done()
      return
    }

    // Column is no longer in the project so abort the move
    const columnID = this.getColumnID(column)
    if (!this.isColumnInState((event as CustomEvent).detail, columnID)) {
      this.done()
      return
    }

    // Store current index and column to move card back to after update
    this.updateSnapshot = {
      columnID,
      indexInColumn: this.getCardIndex(),
    }
  }

  handleUpdateEnd() {
    const snapshot = this.updateSnapshot
    this.updateSnapshot = null

    if (!snapshot || !this.getColumn()) {
      this.done()
      return
    }

    const card = this.findCard(this.getCardID(this.item))
    const column = this.getColumnCards(this.findColumn(snapshot.columnID))
    if (card && column) {
      // Move card back to pending location
      this.moveToColumn(column)
      const afterCard = column.children[snapshot.indexInColumn]!
      if (card !== afterCard) {
        this.moveToBeforeCard(column, afterCard)
      }
    }
  }

  handleKeydown(event: KeyboardEvent) {
    const metaKey = this.isMetaKeyPressed(event)
    if (!isShortcutAllowed(event)) return

    switch (getAction(event.key)) {
      case 'up':
        if (metaKey) {
          this.moveToTop()
        } else {
          this.moveUp()
        }
        event.preventDefault()
        break
      case 'down':
        if (metaKey) {
          this.moveToBottom()
        } else {
          this.moveDown()
        }
        event.preventDefault()
        break
      case 'left':
        if (metaKey) {
          this.moveToFirstColumn(event.shiftKey)
        } else {
          this.moveLeft(event.shiftKey)
        }
        event.preventDefault()
        break
      case 'right':
        if (metaKey) {
          this.moveToLastColumn(event.shiftKey)
        } else {
          this.moveRight(event.shiftKey)
        }
        event.preventDefault()
        break
      case 'confirm':
        this.confirm()
        event.preventDefault()
        break
      case 'cancel':
        this.cancel()
        event.preventDefault()
        break
    }
  }

  // Update the previous position this card was in from the project model
  updatePreviousPosition(state: State): boolean {
    if (!state || !state.columns) return false

    const cardID = this.getCardID(this.item)
    for (const column of state.columns) {
      for (let index = 0; index < column.card_ids.length; index++) {
        if (cardID === column.card_ids[index]!.toString()) {
          this.previousCardID = column.card_ids[index - 1]!.toString()
          this.columnID = column.id.toString()
          return true
        }
      }
    }
    return false
  }

  isColumnInState(state: State, columnID: string): boolean {
    if (!state || !state.columns) return false

    for (const column of state.columns) {
      if (columnID === column.id.toString()) {
        return true
      }
    }
    return false
  }

  getCardIndex(): number {
    const column = this.getColumnCards(this.getColumn())
    return column ? Array.from(column.children).indexOf(this.item) : -1
  }

  getCardCount(): number {
    const column = this.getColumnCards(this.getColumn())
    return column ? column.querySelectorAll('.js-project-column-card').length : 0
  }

  getColumnCards(column?: Element | null): Element | null {
    return column != null ? column.querySelector('.js-project-column-cards') : null
  }

  moveToBeforeCard(column: Element, afterCard: Element) {
    this.moveTransaction(function (card) {
      column.insertBefore(card, afterCard)
    })
  }

  moveToColumn(column: Element) {
    this.moveTransaction(function (card) {
      column.appendChild(card)
    })
  }

  // Move the card up one position
  moveUp() {
    const previousCard = this.item.previousElementSibling
    const column = this.getColumnCards(this.getColumn())
    if (previousCard && column) {
      this.moveToBeforeCard(column, previousCard)
    }
  }

  // Move the card to the top of the column
  moveToTop() {
    const column = this.getColumnCards(this.getColumn())
    if (column) {
      this.moveTransaction(function (card) {
        column.prepend(card)
      })
    }
  }

  // Move the card down one position
  moveDown() {
    const nextCard = this.item.nextElementSibling
    const column = this.getColumnCards(this.getColumn())
    if (nextCard && column) {
      const afterCard = nextCard.nextElementSibling
      if (afterCard) {
        this.moveToBeforeCard(column, afterCard)
      } else {
        this.moveToBottom()
      }
    } else {
      this.moveToBottom()
    }
  }

  // Move the card to the bottom of the column
  moveToBottom() {
    const column = this.getColumnCards(this.getColumn())
    if (column) {
      this.moveToColumn(column)
    }
  }

  // Move the card to the leftmost column
  moveToFirstColumn(topOfColumn: boolean) {
    const firstColumn = this.getColumnCards(this.getFirstColumn())
    if (firstColumn) {
      this.moveToColumn(firstColumn)
      if (topOfColumn) this.moveToTop()
    }
  }

  // Move the card to the rightmost column
  moveToLastColumn(topOfColumn: boolean) {
    const lastColumn = this.getColumnCards(this.getLastColumn())
    if (lastColumn) {
      this.moveToColumn(lastColumn)
      if (topOfColumn) this.moveToTop()
    }
  }

  // Move the card to the column on the left
  moveLeft(topOfColumn: boolean) {
    const targetColumn = this.getColumnCards(this.getPane() ? this.getLastColumn() : this.getPreviousColumn())
    if (targetColumn) {
      this.moveToColumn(targetColumn)
      if (topOfColumn) this.moveToTop()
    }
  }

  // Move the card to the column on the right
  moveRight(topOfColumn: boolean) {
    const nextColumn = this.getColumnCards(this.getNextColumn())
    if (nextColumn) {
      this.moveToColumn(nextColumn)
      if (topOfColumn) this.moveToTop()
    }
  }

  override done() {
    super.done()
    this.item.removeEventListener('keydown', this.keydownListener)
    this.getColumnsContainer().removeEventListener('will-update-project', this.updateStartListener)
    this.getColumnsContainer().removeEventListener('update-project', this.updateEndListener)
  }

  // Move card to new position
  confirm() {
    if (this.moved) {
      let fromColumn = this.findColumn(this.columnID)
      if (this.pendingCard && !fromColumn) {
        fromColumn = document.querySelector('.js-project-pending-cards-container.js-project-column')
      }
      this.moveCard(this.item, fromColumn, {keyboardMove: true})
    }
    this.done()
  }

  // Restore card to original position
  override cancel() {
    let column
    let afterCard
    if (this.moved) {
      if (this.previousCardID) {
        const previousCard = this.findCard(this.previousCardID)
        if (previousCard) {
          column = previousCard.closest('.js-project-column-cards')
          afterCard = previousCard.nextElementSibling
        }
      } else if (this.columnID) {
        column = this.getColumnCards(this.findColumn(this.columnID))
        if (column) {
          afterCard = column.firstElementChild
        }
      } else if (this.paneColumnCards) {
        column = this.paneColumnCards
        afterCard = column.firstElementChild
      }
    }

    if (column) {
      if (afterCard) {
        this.moveToBeforeCard(column, afterCard)
      } else {
        this.moveToColumn(column)
      }
    }
    this.done()
  }
}

// Re-order a column using the keyboard
class ColumnMove extends ItemMove {
  nextColumnID: string
  moveColumn: (el: Element, opts?: KeyboardMoveOptions) => void
  keydownListener: (event: KeyboardEvent) => void
  updateListener: (event: Event) => void

  constructor(column: HTMLElement, moveColumn: (el: Element, opts?: KeyboardMoveOptions) => void, onDone: () => void) {
    super(column, onDone)
    this.moveColumn = moveColumn
    this.nextColumnID = this.getColumnID(column.nextElementSibling)

    this.keydownListener = this.handleKeydown.bind(this)
    this.item.addEventListener('keydown', this.keydownListener)

    // Abort move if columns were updated by someone else
    this.updateListener = this.done.bind(this)
    this.getColumnsContainer().addEventListener('will-update-project-columns', this.updateListener)
  }

  override updateAriaLabel() {
    const index = this.getColumnIndex()
    const indexLabel = index >= 0 ? `to position ${index + 1} of ${this.getColumnCount()}` : ''
    this.item.setAttribute('aria-label', `Moved ${this.getColumnName()} column ${indexLabel}`.trim())
  }

  getColumnIndex(): number {
    const columns = this.getColumnsContainer().querySelectorAll('.js-project-column')
    return Array.from(columns).indexOf(this.item)
  }

  getColumnCount(): number {
    return this.getColumnsContainer().querySelectorAll('.js-project-column').length
  }

  handleKeydown(event: KeyboardEvent) {
    if (!isShortcutAllowed(event)) return

    const metaKey = this.isMetaKeyPressed(event)
    switch (getAction(event.key)) {
      case 'left':
        if (metaKey) {
          this.moveToFirstColumn()
        } else {
          this.moveLeft()
        }
        event.preventDefault()
        break
      case 'right':
        if (metaKey) {
          this.moveToLastColumn()
        } else {
          this.moveRight()
        }
        event.preventDefault()
        break
      case 'confirm':
        this.confirm()
        event.preventDefault()
        break
      case 'cancel':
        this.cancel()
        event.preventDefault()
        break
    }
  }

  insertBefore(afterColumn: Element) {
    const container = this.getColumnsContainer()
    this.moveTransaction(function (column) {
      container.insertBefore(column, afterColumn)
    })
  }

  // Move the column to the left by one column
  moveLeft() {
    const previousColumn = this.getPreviousColumn()
    if (previousColumn) {
      this.insertBefore(previousColumn)
    }
  }

  // Move the columm to the right by one column
  moveRight() {
    const nextColumn = this.getNextColumn()
    if (nextColumn) {
      const nextSibling = nextColumn.nextElementSibling
      if (nextSibling && this.isColumn(nextSibling)) {
        this.insertBefore(nextSibling)
      } else {
        this.moveToLastColumn()
      }
    } else {
      this.moveToLastColumn()
    }
  }

  // Move this column to be the leftmost column
  moveToFirstColumn() {
    const firstColumn = this.getFirstColumn()
    if (firstColumn) {
      this.insertBefore(firstColumn)
    }
  }

  // Move this column to be the rightmost column
  moveToLastColumn() {
    const lastColumn = this.getLastColumn()
    if (lastColumn) {
      const nextSibling = lastColumn.nextElementSibling
      if (nextSibling) {
        this.insertBefore(nextSibling)
      }
    }
  }

  override done() {
    super.done()
    this.item.removeEventListener('keydown', this.keydownListener)
    this.getColumnsContainer().removeEventListener('will-update-project-columns', this.updateListener)
  }

  // Move column to new position
  confirm() {
    if (this.moved) {
      this.moveColumn(this.item, {keyboardMove: true})
    }
    this.done()
  }

  // Restore column to original position
  override cancel() {
    if (this.moved) {
      const nextColumn = this.findColumn(this.nextColumnID)
      if (nextColumn) {
        this.insertBefore(nextColumn)
      } else {
        this.moveToLastColumn()
      }
    }
    this.done()
  }
}

export function enableKeyboardMovements(
  moveCardFn: (el: Element, el2?: Element | null, opts?: KeyboardMoveOptions) => void,
  moveColumnFn: (el: Element, options?: KeyboardMoveOptions) => void,
) {
  let cardMove: CardMove | null
  let columnMove: ColumnMove | null

  function onCardKeypress(event: KeyboardEvent) {
    if (cardMove) return

    const card = event.currentTarget as HTMLElement
    if (!isShortcutAllowed(event)) return

    switch (getAction(event.key)) {
      case 'confirm':
      case 'Space':
        // Prevent redacted cards from being moved
        if (card.classList.contains('js-redacted-project-column-card')) return

        // Prevent people without write access from moving cards
        if (!card.classList.contains('js-keyboard-movable')) return

        cardMove = new CardMove(card, moveCardFn, function onDone() {
          cardMove = null
        })
        event.preventDefault()
        break
      case 'up': {
        const previousCard = card.previousElementSibling as HTMLElement
        if (previousCard && previousCard.classList.contains('js-project-column-card')) {
          previousCard.focus()
          event.preventDefault()
        } else {
          const column = card.closest('.js-project-column')
          if (column instanceof HTMLElement) {
            column.focus()
            event.preventDefault()
          }
        }
        break
      }
      case 'down': {
        const nextCard = card.nextElementSibling as HTMLElement
        if (nextCard && nextCard.classList.contains('js-project-column-card')) {
          nextCard.focus()
          event.preventDefault()
        }
        break
      }
    }
  }

  function onColumnKeypress(event: KeyboardEvent) {
    if (columnMove) return

    // Columns contain the Add Note input field so ignore the event if  the
    // keypress was generated by it since it denotes typing into the note box
    if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) return

    const column = event.currentTarget as HTMLElement

    switch (getAction(event.key)) {
      case 'confirm':
      case 'Space':
        // Prevent people without write access from moving columns
        if (!column.classList.contains('js-keyboard-movable')) return

        columnMove = new ColumnMove(column, moveColumnFn, function onDone() {
          columnMove = null
        })
        event.preventDefault()
        break
      case 'down': {
        const firstCard = column.querySelector<HTMLElement>('.js-project-column-card')
        if (firstCard) {
          firstCard.focus()
          event.preventDefault()
        }
        break
      }
      case 'left': {
        const previousColumn = column.previousElementSibling as HTMLElement
        if (previousColumn && previousColumn.classList.contains('js-project-column')) {
          previousColumn.focus()
          event.preventDefault()
        }
        break
      }
      case 'right': {
        const nextColumn = column.nextElementSibling as HTMLElement
        if (nextColumn && nextColumn.classList.contains('js-project-column')) {
          nextColumn.focus()
          event.preventDefault()
        }
        break
      }
    }
  }

  onFocus('.js-project-column-card', function (card) {
    if (cardMove) {
      if (cardMove.isMoving(card)) {
        return
      } else {
        cardMove.cancel()
      }
    }

    card.addEventListener('keydown', onCardKeypress)
    card.addEventListener('blur', function onBlur() {
      if (cardMove && cardMove.isMoving(card)) return
      card.removeEventListener('blur', onBlur)
      card.removeEventListener('keydown', onCardKeypress)
    })
  })

  onFocus('.js-project-column', function (column) {
    if (columnMove) {
      if (columnMove.isMoving(column)) {
        return
      } else {
        columnMove.cancel()
      }
    }

    column.addEventListener('keydown', onColumnKeypress)
    column.addEventListener('blur', function onBlur() {
      if (columnMove && columnMove.isMoving(column)) return
      column.removeEventListener('blur', onBlur)
      column.removeEventListener('keydown', onColumnKeypress)
    })
  })
}
