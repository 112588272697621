import {animate, recordPositions} from './magic-move'

import {IncludeFragmentElement} from '@github/include-fragment-element'
import {debounce} from '@github/mini-throttle'
// eslint-disable-next-line no-restricted-imports
import {fire} from 'delegated-events'

let updateMessageTimer: number | undefined

export function displayUpdateMessage(message: string) {
  if (!message) {
    return
  }

  if (updateMessageTimer) {
    clearTimeout(updateMessageTimer)
  }

  const messageContainer = document.querySelector<HTMLElement>('.js-project-updated-message')!
  messageContainer.textContent = message
  /* eslint-disable-next-line github/no-d-none */
  messageContainer.classList.remove('d-none')
  updateMessageTimer = window.setTimeout(function () {
    messageContainer.textContent = ''
    /* eslint-disable-next-line github/no-d-none */
    messageContainer.classList.add('d-none')
  }, 3000)
}

// Either grabs the existing column element for this Column, or creates
// an <include-fragment> element that will load the Column's HTML from the server
function getColumn(column: Column, urlBase: string): HTMLElement {
  const columnIDAttribute = `column-${column.id}`
  const columnElem = document.getElementById(columnIDAttribute)
  if (columnElem) {
    return columnElem
  } else {
    const columnPlaceholder = new IncludeFragmentElement()
    columnPlaceholder.id = columnIDAttribute
    columnPlaceholder.src = encodeURI(`${urlBase}/${column.id}`)
    return columnPlaceholder
  }
}

// Either grabs the existing card element for this Card, or creates
// an <include-fragment> element that will load the Card's HTML from the server
function getCard(cardID: string, urlBase: string): HTMLElement {
  const cardIDAttribute = `card-${cardID}`
  const cardElem = document.getElementById(cardIDAttribute)
  if (cardElem) {
    return cardElem
  } else {
    const cardPlaceholder = new IncludeFragmentElement()
    cardPlaceholder.id = cardIDAttribute
    cardPlaceholder.src = encodeURI(`${urlBase}/${cardID}`)
    cardPlaceholder.onerror = function () {
      cardPlaceholder.remove()
    }

    return cardPlaceholder
  }
}

function shouldUpdateColumns(projectContainer: HTMLElement, projectJSON: {columns: Column[]}): boolean {
  const columns = Array.from(projectContainer.querySelectorAll('.js-project-column'))
  const columnIDsFromContainer = columns.map(column => {
    return column.getAttribute('data-id')
  })
  const columnIDsFromJSON = projectJSON.columns.map(column => {
    return String(column.id)
  })

  return columnIDsFromJSON.join(',') !== columnIDsFromContainer.join(',')
}

interface Column {
  id: number
  name: string
  card_ids: number[]
  purpose: string
  automation_summary: string
}

function updateColumnAutomation(column: Column, columnElement: HTMLElement) {
  const automationFooter = columnElement.querySelector('.js-project-column-automation-footer')
  if (!automationFooter) return

  const automationSummary = automationFooter.querySelector<HTMLElement>('.js-project-column-automation-summary')!
  if (column.automation_summary && column.purpose) {
    automationSummary.textContent = column.purpose
    automationSummary.setAttribute('aria-label', column.automation_summary)
    /* eslint-disable-next-line github/no-d-none */
    automationFooter.classList.remove('d-none')
  } else {
    /* eslint-disable-next-line github/no-d-none */
    automationFooter.classList.add('d-none')
  }
}

// Populate a column nav element with the column id, name, and card count
function populateColumnNav(navItem: HTMLAnchorElement, columnID: string, columnName: string, cardCount: string) {
  navItem.setAttribute('data-column-id', columnID)
  navItem.setAttribute('data-column-name', columnName)
  navItem.href = encodeURI(`#column-${columnID}`)
  navItem.querySelector<HTMLElement>('.js-column-nav-name')!.textContent = columnName

  const cardCounter = navItem.querySelector<HTMLElement>('.js-column-nav-card-count')!
  if (cardCounter) {
    cardCounter.textContent = cardCount

    // eslint-disable-next-line i18n-text/no-en
    cardCounter.setAttribute('aria-label', `Contains ${cardCount} ${Number(cardCount) === 1 ? 'item' : 'items'}`)
  }
}

// Create and append a new column nav element to the column nav header shown
// on small screens
function createColumnNav(
  template: HTMLTemplateElement,
  columnNav: HTMLElement,
  columnID: string,
  columnName: string,
  cardCount: string,
  selected: boolean,
) {
  const navLink = template.content.cloneNode(true) as DocumentFragment

  const navItem = navLink.querySelector<HTMLAnchorElement>('.js-project-column-navigation-item')!
  navItem.classList.toggle('selected', selected)
  populateColumnNav(navItem, columnID, columnName, cardCount)

  const nextIndex = columnNav.querySelectorAll('.js-project-column-navigation-item').length + 1
  navLink.querySelector<HTMLElement>('.js-column-nav-index')!.textContent = nextIndex.toString()

  columnNav.appendChild(navLink)
}

// Remove the column nav element with the given column ID from the column nav
// header shown on small screens
export function deleteColumnNav(columnID: string) {
  for (const column of document.querySelectorAll('.js-project-column-navigation-item')) {
    if (columnID === column.getAttribute('data-column-id')) {
      column.remove()
      return
    }
  }
}

// Add a column nav element to the column nav header shown on small screens
// representing the given column element
export function addColumnNav(column: HTMLElement) {
  const columnNav = document.querySelector<HTMLElement>('.js-project-column-nav')
  if (!columnNav) return

  const template = columnNav.querySelector<HTMLTemplateElement>('.js-column-nav-template')!
  const columnID = column.getAttribute('data-id') || ''
  const columnName = column.querySelector<HTMLElement>('.js-project-column-name')!.textContent!
  const cardCount = column.querySelector<HTMLElement>('.js-column-card-count')!.textContent!
  createColumnNav(template, columnNav, columnID, columnName, cardCount, true)
}

// Update a column nav element shown on small screens for the given column
// element
export function updateColumnNav(column: HTMLElement) {
  const columnID = column.getAttribute('data-id')
  if (!columnID) return

  const navItem = document.querySelector(`.js-project-column-navigation-item[data-column-id="${columnID}"]`)
  if (navItem instanceof HTMLAnchorElement) {
    const columnName = column.querySelector<HTMLElement>('.js-project-column-name')!.textContent!
    const cardCount = column.querySelector<HTMLElement>('.js-column-card-count')!.textContent!
    populateColumnNav(navItem, columnID, columnName, cardCount)
  }
}

// Rebuild the column nav shown on small screens for the current columns present
// in the data model
function rebuildColumnNav(columns: Column[]) {
  const columnNav = document.querySelector<HTMLElement>('.js-project-column-nav')
  if (!columnNav) return

  const template = columnNav.querySelector<HTMLTemplateElement>('.js-column-nav-template')!

  const scrollLeft = columnNav.scrollLeft

  let activeColumnID
  for (const column of document.querySelectorAll('.js-project-column-navigation-item')) {
    column.remove()
    if (column.classList.contains('selected')) {
      activeColumnID = column.getAttribute('data-column-id')
    }
  }

  for (const column of columns) {
    createColumnNav(
      template,
      columnNav,
      column.id.toString(),
      column.name,
      column.card_ids.length.toString(),
      activeColumnID === column.id.toString(),
    )
  }

  columnNav.scrollLeft = scrollLeft
}

function replaceCardsInContainer(
  cardContainer: HTMLElement,
  cardIDs: number[],
  cardElements: {[index: number]: HTMLElement},
) {
  // Build updated card list for this container
  const cardsFragment = document.createDocumentFragment()
  for (const cardID of cardIDs) {
    cardsFragment.appendChild(cardElements[cardID]!)
  }

  // Swap updated card list into the page
  cardContainer.textContent = ''
  cardContainer.appendChild(cardsFragment)
}

// Updates a project when an event comes in from the project's channel
type ProjectUpdateData = {state: {columns: Column[]}; message: string}
async function _updateProject(projectContainer: HTMLElement, data: ProjectUpdateData) {
  displayUpdateMessage(data.message)

  const columns = projectContainer.querySelectorAll<HTMLElement>('.js-project-column')
  const columnPositions = recordPositions(columns)

  const projectURL = projectContainer.getAttribute('data-url') || ''
  const columnsURL = projectContainer.getAttribute('data-columns-url') || ''
  const cardsURL = projectContainer.getAttribute('data-cards-url') || ''

  // Loads a representation of the project's columns and ordered card IDs
  let projectJSON = data.state
  if (!projectJSON) {
    const response = await fetch(projectURL, {
      headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
    })
    if (!response.ok) {
      return
    }
    projectJSON = await response.json()
  }

  fire(projectContainer, 'will-update-project', projectJSON)

  const oldActiveElement = document.activeElement as HTMLElement

  if (shouldUpdateColumns(projectContainer, projectJSON)) {
    fire(projectContainer, 'will-update-project-columns')

    // Save scroll position
    const scrollPositions: {[key: string]: number} = {}

    // Build updated column list
    const columnsFragment = document.createDocumentFragment()
    for (const columnID of projectJSON.columns) {
      const column = getColumn(columnID, columnsURL)
      const cardContainer = column.querySelector('.js-project-column-cards')
      if (cardContainer) {
        scrollPositions[cardContainer.id] = cardContainer.scrollTop
      }
      columnsFragment.appendChild(column)
    }

    // Grab the "Add column" button because we're going to be swapping
    // out the contents of its parent
    const newColumnButton = projectContainer.querySelector('.js-new-project-column-container')
    if (newColumnButton) {
      newColumnButton.remove()
    }

    const blankSlate = projectContainer.querySelector<HTMLElement>('.js-new-column-blankslate')!
    blankSlate.remove()

    // Swap updated column list into the page
    projectContainer.textContent = ''
    projectContainer.appendChild(columnsFragment)

    // Put the "Add column" button back
    if (newColumnButton) {
      projectContainer.appendChild(newColumnButton)
      // make it visible if there are any columns
      const button = newColumnButton.querySelector<HTMLElement>('.js-new-column-button')!
      /* eslint-disable-next-line github/no-d-none */
      button.classList.toggle('d-none', projectJSON.columns.length === 0)
    }

    projectContainer.appendChild(blankSlate)
    /* eslint-disable-next-line github/no-d-none */
    blankSlate.classList.toggle('d-none', projectJSON.columns.length > 0)

    rebuildColumnNav(projectJSON.columns)

    // Restore scroll positions for columns
    for (const cardContainerID in scrollPositions) {
      const cardContainer = document.getElementById(cardContainerID)!
      cardContainer.scrollTop = scrollPositions[cardContainerID]!
    }

    await animate(columns, columnPositions)
  }

  const cards = projectContainer.querySelectorAll<HTMLElement>('.js-project-column-card')
  const cardPositions = recordPositions(cards)

  const cardElements: {[index: number]: HTMLElement} = {}

  // Store all existing card elements from columns before updating them so
  // cards get re-used when moving between columns.
  for (const column of projectJSON.columns) {
    for (const cardID of column.card_ids) {
      cardElements[cardID] = getCard(cardID.toString(), cardsURL)
    }
  }

  for (const column of projectJSON.columns) {
    const columnElem = document.getElementById(`column-${column.id}`)

    // Column is loading, but it will come in with the cards
    if (!columnElem || columnElem.tagName === 'INCLUDE-FRAGMENT') {
      return
    }

    // Update the name
    const columnNameElem = columnElem.querySelector('.js-project-column-name')

    if (columnNameElem) {
      columnNameElem.textContent = column.name

      const columnNameField = columnElem.querySelector('.js-project-column-name-field')

      // There won't be a column form for read only viewers
      if (columnNameField instanceof HTMLInputElement) {
        columnNameField.value = column.name
        // Facebox copies a serialized version of the element instead of cloning the node,
        // so we need to set the attribute directly
        columnNameField.setAttribute('value', column.name)
      }
    }

    const columnCountElem = columnElem.querySelector('.js-column-card-count')
    if (columnCountElem) {
      columnCountElem.textContent = column.card_ids.length.toString()
    }

    updateColumnAutomation(column, columnElem)

    const cardContainer = columnElem.querySelector<HTMLElement>('.js-project-column-cards')!
    replaceCardsInContainer(cardContainer, column.card_ids, cardElements)
  }

  fire(projectContainer, 'update-project')

  await animate(cards, cardPositions)

  // Restore active element (like if a user is typing a Note)
  if (oldActiveElement && document.activeElement !== oldActiveElement) {
    oldActiveElement.focus()
  }
}

export const updateProject: (el: HTMLElement, data: ProjectUpdateData) => void = debounce(_updateProject, 100)

export function updateProjectColumnAutomation(projectJSON: {columns: Column[]}) {
  for (const column of projectJSON.columns) {
    const columnElement = document.getElementById(`column-${column.id}`)
    if (columnElement) {
      updateColumnAutomation(column, columnElement)
    }
  }
}
