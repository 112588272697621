// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import {toggleDetailsTarget} from '../behaviors/details'

on('submit', '.js-project-name-update', async function (event) {
  const form = event.currentTarget as HTMLFormElement
  const container = form.closest<HTMLElement>('.js-details-container')!

  event.preventDefault()
  let response
  let json

  try {
    response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    json = await response.json()
  } catch {
    // Ignore network errors
  }

  if (response && !response.ok) {
    const formGroup = form.querySelector<HTMLElement>('.js-project-form-group')
    const errorContainer = container.querySelector<HTMLElement>('.js-project-form-error')
    const fallbackErrorMessage = form.getAttribute('data-fallback-error-message')

    if (errorContainer) {
      errorContainer.textContent = json.errors?.[0] || fallbackErrorMessage
    }

    if (formGroup) {
      formGroup.classList.add('errored')
    }
  } else if (json) {
    container.querySelector<HTMLElement>('.js-project-name-label')!.textContent = json.project_name
    toggleDetailsTarget(container, {force: false})

    // bust the hovercard cache by updating the `ts` querystring value
    const hoverCard = container.querySelector<HTMLElement>('.js-project-hovercard')
    if (hoverCard) {
      const path = hoverCard.getAttribute('data-hovercard-url')
      const timestampRegex = /\?ts=(.+)$/

      if (path?.match(timestampRegex)) {
        hoverCard.setAttribute('data-hovercard-url', path.replace(timestampRegex, `?ts=${Date.now()}`))
      }
    }
  }

  // prevent `Failed to update content with interactions` warnings
  resetDefaultFormValues(form)

  // reset the disable-with on the submit button
  fire(form, 'deprecatedAjaxComplete')
})

function resetDefaultFormValues(form: HTMLFormElement) {
  // Here, we reset the form's input fields' `defaultValue`s.
  // We have to take this step because of the .js-updatable-content
  // handler, which we rely on to "live-update" the form.
  //
  // If the form's input fields are in a "dirty" state, the handler
  // will refuse to replace the div with our data-url show_partial's
  // content. Consequently, we reset the fields' state.

  for (const field of form.elements) {
    if (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement) {
      field.defaultValue = field.value
    }
  }
}
