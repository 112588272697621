import {getState, replaceState} from '@github-ui/browser-history-state'

const isQualifier = 'is'
const linkedQualifier = 'linked'
const titleQualifier = 'title'

const qualifiers = [
  'assignee',
  'author',
  isQualifier,
  'label',
  'milestone',
  'repo',
  'review',
  'state',
  'status',
  'type',
  linkedQualifier,
]

// Mapping from linked qualifier value to applicable type
// e.g. linked:pr should map to issue
const linkedQualifierValueTypeMapping: {[index: string]: string} = {
  pr: 'issue',
}

const typeQualifierValues = ['issue', 'pr', 'note']

// Qualifiers that can be specified with a 'no:'' prefix such as 'no:assignee'
const noQualifiers = ['assignee', 'label', 'milestone']

const queryTokenizerRegex = /[^\s:]+:("(?:\\"|.)*?"|[^\s]*)|[^\s]+/g

interface TokenFilter {
  token: string
  negated: boolean
}

interface QualifierFilter {
  tokenFilters: TokenFilter[]
  matchIfMissing: boolean
}

interface ProjectCardFilter {
  [index: string]: QualifierFilter | undefined
  assignee?: QualifierFilter
  author?: QualifierFilter
  is?: QualifierFilter
  label?: QualifierFilter
  milestone?: QualifierFilter
  repo?: QualifierFilter
  state?: QualifierFilter
  title?: QualifierFilter
  type?: QualifierFilter
}

function getOrCreateQualifierFilter(filter: ProjectCardFilter, qualifier: string): QualifierFilter {
  let qualifierFilter = filter[qualifier]
  if (!qualifierFilter) {
    qualifierFilter = {tokenFilters: [], matchIfMissing: false}
    filter[qualifier] = qualifierFilter
  }
  return qualifierFilter
}

function addToken(filter: QualifierFilter, token: string, negated: boolean) {
  const tokenFilter = filter.tokenFilters.find(tf => tf.token === token)
  if (tokenFilter) {
    tokenFilter.negated = negated
  } else {
    filter.tokenFilters.push({token, negated})
  }
  // Reset matchIfMissing since last qualifier token wins
  filter.matchIfMissing = false
}

export function queryContainsQualifier(queryString: string, qualifier: string): boolean {
  return parseCardFilterQuery(queryString)[qualifier] != null
}

function getQueryTokenValue(queryToken: string, qualifier: string): string {
  // If the token starts with a valid qualifier (i.e. 'author:'), add it to
  // the filter, replacing any escaped characters for matching
  const regexPattern = `^-?${qualifier}:\\s*"?(.*?)"?\\s*$`
  return queryToken.replace(new RegExp(regexPattern), '$1').replace(/\\(")/g, '$1').toLowerCase()
}

function parseCardFilterQuery(queryString: string): ProjectCardFilter {
  // Split the query up into tokens.
  const queryTokens: string[] = queryString.match(queryTokenizerRegex) || []
  const filter: ProjectCardFilter = {}

  for (const queryToken of queryTokens) {
    // See if the token matches any qualifiers. Here are some examples of what
    // matches and what doesn't:
    //
    // Matches:
    // --------
    // label:bug
    // label:b
    // label:"multiword label"
    // -label:question
    //
    // Does not match:
    // ---------------
    // label: bug (this will be split into two tokens, neither of which match any qualifiers)
    // label:
    // label
    // invalidqualifier:bug
    // someothertoken
    const matchingQualifier = qualifiers.find(qualifier => queryToken.match(new RegExp(`^-?${qualifier}:.*`)))

    if (matchingQualifier) {
      const newQualifierValue = getQueryTokenValue(queryToken, matchingQualifier)

      if (newQualifierValue.length > 0) {
        // Only add the filter if it has a value. Otherwise, ignore it.
        const qualifierFilter = getOrCreateQualifierFilter(filter, matchingQualifier)
        const negated = queryToken.startsWith('-')
        addToken(qualifierFilter, newQualifierValue, negated)

        // if linked filter is used and no type filter is present,
        // add type filter to scope the result to proper type for linked filter
        if (matchingQualifier !== linkedQualifier) {
          continue
        }

        const targetTypeValue = linkedQualifierValueTypeMapping[newQualifierValue]
        const hasTypeFilter = (tokens: string[]) =>
          tokens.some(token => {
            const tokenValue = getQueryTokenValue(token, isQualifier)
            return token !== tokenValue && typeQualifierValues.includes(tokenValue)
          })
        if (targetTypeValue && !hasTypeFilter(queryTokens)) {
          const isQualifierFilter = getOrCreateQualifierFilter(filter, isQualifier)
          addToken(isQualifierFilter, targetTypeValue, false)
        }
      }
    } else {
      const missingQualifier = noQualifiers.find(qualifier => queryToken === `no:${qualifier}`)
      if (missingQualifier) {
        getOrCreateQualifierFilter(filter, missingQualifier).matchIfMissing = true
      } else if (queryToken.length > 0) {
        // If the token doesn't start with a valid qualifier, make it part of the
        // card title qualifier.
        const qualifierFilter = getOrCreateQualifierFilter(filter, titleQualifier)
        addToken(qualifierFilter, queryToken, false)
      }
    }
  }

  // After all this parsing, a query string that looked like:
  //
  // 'one two three author:jakeboxer label:bug -label:wontfix no:assignee'
  //
  // will result in an object that looks like:
  //
  // {
  //   author: {
  //     tokens: [
  //       {token: 'jakeboxer', negated: false}
  //     ],
  //     matchIfMissing: false
  //   },
  //   assignee: {
  //     tokens: [],
  //     matchIfMissing: true
  //   },
  //   label: {
  //     tokens: [
  //       {token: 'bug', negated: false},
  //       {token: 'wontfix', negated: true}
  //     ],
  //     matchIfMissing: true
  //   },
  //   title: {
  //     tokens: [
  //       {token: 'one', negated: false},
  //       {token: 'two' negated: false},
  //       {token: 'three', negated: false}
  //     ],
  //     matchIfMissing: false
  //   }
  // }
  return filter
}

let parsedFilter: ProjectCardFilter
let lastQueryText: string

function getQueryText() {
  return document.querySelector<HTMLInputElement>('.js-card-filter-input')?.value.trim().toLowerCase() || ''
}

function getFilterQuery(): ProjectCardFilter {
  const queryText = getQueryText()
  if (lastQueryText !== queryText) {
    lastQueryText = queryText
    parsedFilter = parseCardFilterQuery(queryText)
  }
  return parsedFilter
}

function cardMatchesFilter(card: HTMLElement, filter: ProjectCardFilter): boolean {
  for (const qualifier in filter) {
    // Split the card's attribute value into tokens.
    const cardAttrValue = card.getAttribute(`data-card-${qualifier}`) || ''
    let cardAttrTokens = []

    if (cardAttrValue.trim() !== '') {
      cardAttrTokens = JSON.parse(cardAttrValue)
    }

    const qualifierFilter = filter[qualifier]!
    if (qualifierFilter.matchIfMissing) {
      // If the tokens are not empty and no:qualifier was specified, exit
      // early.
      if (cardAttrTokens.length > 0) {
        return false
      }
    } else {
      // Iterate over each token in the qualifier and see if it's matched by one
      // of the card's tokens.
      for (const qualifierToken of qualifierFilter.tokenFilters) {
        if (qualifier === titleQualifier) {
          if (!isPartiallyMatched(qualifierToken.token, cardAttrTokens)) {
            // If one of the tokens in the title qualifier isn't partially
            // matched, exit early.
            return false
          }
        } else {
          if (qualifier === linkedQualifier && !linkedQualifierValueTypeMapping[qualifierToken.token]) {
            // Exit early if the 'linked' qualifier is not supported
            return false
          }

          const fullyMatched = isFullyMatched(qualifierToken.token, cardAttrTokens)
          if (qualifierToken.negated) {
            if (fullyMatched && cardAttrTokens.length > 0) {
              // If one of the tokens in another qualifier is matched but was
              // negated, exit early
              return false
            }
          } else if (!fullyMatched) {
            // If one of the tokens in another qualifier isn't fully matched, exit
            // early.
            return false
          }
        }
      }
    }
  }

  // If all the tokens in all the qualifiers were matched, this card matches.
  return true
}

function isPartiallyMatched(needle: string, haystack: string[]): boolean {
  return haystack.some(item => {
    return item.startsWith(needle)
  })
}

function isFullyMatched(needle: string, haystack: string[]): boolean {
  return haystack.indexOf(needle) !== -1
}

function setQueryParam(name: string, value: string): void {
  const newUrl = new URL(window.location.href, window.location.origin)
  const params = new URLSearchParams(newUrl.search.slice(1))

  if (value === '') {
    params.delete(name)
  } else {
    params.set(name, value)
  }

  newUrl.search = params.toString()
  replaceState(getState(), document.title, newUrl.toString())
}

function updateFullscreenLinks(name: string, value: string): void {
  for (const link of document.querySelectorAll('.js-project-fullscreen-link')) {
    const newUrl = new URL(link.getAttribute('href') || '', window.location.origin)
    const params = new URLSearchParams(newUrl.search.slice(1))

    if (value === '') {
      params.delete(name)
    } else {
      params.set(name, value)
    }

    newUrl.search = params.toString()
    link.setAttribute('href', newUrl.toString())
  }
}

export function updateFilteredCardCounts() {
  const queryText = getQueryText()

  for (const column of document.querySelectorAll('.js-project-columns-container .js-project-column')) {
    const filteredCountLabel = column.querySelector<HTMLElement>('.js-filtered-column-card-count')
    if (!filteredCountLabel) continue

    if (queryText.length > 0) {
      // Update and show the filtered count if there's a query
      const filteredCount = column.querySelectorAll('.js-project-column-card:not(.d-none)').length
      filteredCountLabel.textContent = `${filteredCount.toString()} result${filteredCount !== 1 ? 's' : ''}`
    }

    // Hide the filtered count if there's no query
    /* eslint-disable-next-line github/no-d-none */
    filteredCountLabel.classList.toggle('d-none', queryText.length === 0)
  }
}

export function filterCard(card: HTMLElement) {
  const filter = getFilterQuery()
  let cardMatches = cardMatchesFilter(card, filter)

  if (!cardMatches) {
    for (const cardReference of card.querySelectorAll<HTMLElement>('.js-issue-note, .js-issue-note-reference')) {
      cardMatches = cardMatchesFilter(cardReference, filter)
      if (cardMatches) {
        break
      }
    }
  }

  /* eslint-disable-next-line github/no-d-none */
  card.classList.toggle('d-none', !cardMatches)
}

export function updateFilterState() {
  const input = document.querySelector<HTMLInputElement>('.js-card-filter-input')
  if (!input) return

  const queryText = getQueryText()

  // Hide the "Clear filters" button if there's no query
  const cardFilterClearButton = document.querySelector<HTMLElement>('.js-card-filter-clear')
  /* eslint-disable-next-line github/no-d-none */
  cardFilterClearButton?.classList.toggle('d-none', queryText.length === 0)

  setQueryParam(input.name, queryText)
  updateFullscreenLinks(input.name, queryText)
}

export function filterCards(): void {
  for (const card of document.querySelectorAll<HTMLElement>('.js-project-columns-container .js-project-column-card')) {
    filterCard(card)
  }

  updateFilteredCardCounts()
  updateFilterState()
}

// The text before the cursor should NOT have quotes after a colon.
const badTextBeforeCursorRegex = /:"[^"]*"?$/

// The text before the cursor should be one of these two things:
//
// 1. The beginning of the string (for the first token)
// 2. 1 or more whitespace characters (for any token after the first)
//
// followed by any number of characters that are not whitespace or ":" (i.e.
// alphanumeric characters, puncuation, and some other obscure things).
const goodTextBeforeCursorRegex = /(^|\s+)[^\s:]+$/

// The text after the cursor should be one of these two things:
//
// 1. Whitespace only (to support adding new tokens in between existing ones)
// 2. The end of the string (i.e. there's no text after the cursor)
const goodTextAfterCursorRegex = /^(\s|$)/

function shouldShowSuggestions(inputField: HTMLInputElement): boolean {
  // Only show suggestions when input field is focused
  if (document.activeElement !== inputField) {
    return false
  }

  const textBeforeCursor = inputField.value.slice(0, inputField.selectionEnd!)
  const textAfterCursor = inputField.value.slice(inputField.selectionEnd!)
  const isEmpty = inputField.value.trim().length === 0

  if (badTextBeforeCursorRegex.test(textBeforeCursor)) {
    return false
  }

  const isCursorAtSuggestionLocation =
    goodTextBeforeCursorRegex.test(textBeforeCursor) && goodTextAfterCursorRegex.test(textAfterCursor)

  return isEmpty || isCursorAtSuggestionLocation
}

export function updateSuggestionsDisplay(inputField: HTMLInputElement): void {
  shouldShowSuggestions(inputField) ? showSuggestions(inputField) : hideSuggestions()
}

const showSuggestionHelperRegex = /(^|\s)[^\s:]+$/
const currentTokenRegex = /\S*$/

function showSuggestions(inputField: HTMLInputElement): void {
  const suggestionsContainer = document.querySelector('.js-card-filter-suggester')

  if (suggestionsContainer instanceof HTMLElement) {
    suggestionsContainer.classList.add('js-active-navigation-container')
    /* eslint-disable-next-line github/no-d-none */
    suggestionsContainer.classList.remove('d-none')

    const textBeforeCursor = inputField.value.slice(0, inputField.selectionEnd!)
    const currentToken = (textBeforeCursor.match(currentTokenRegex) || [])[0]!
    const suggestionHelperContainer = suggestionsContainer.querySelector<HTMLElement>(
      '.js-card-filter-suggester-helper-container',
    )!
    const suggestionHelper = suggestionHelperContainer.querySelector<HTMLElement>('.js-card-filter-suggester-helper')!

    if (showSuggestionHelperRegex.test(inputField.value)) {
      suggestionHelper.textContent = inputField.value
      /* eslint-disable-next-line github/no-d-none */
      suggestionHelperContainer.classList.remove('d-none')
    } else {
      /* eslint-disable-next-line github/no-d-none */
      suggestionHelperContainer.classList.add('d-none')
    }

    const suggestionsHeader = suggestionsContainer.querySelector<HTMLElement>('.js-card-filter-suggestions-header')!
    let showSuggestionsHeader = false

    for (const suggestion of suggestionsContainer.querySelectorAll('.js-card-filter-suggestion')) {
      const suggestionValue = suggestion.getAttribute('data-value')

      if (suggestionValue && suggestionValue.startsWith(currentToken)) {
        /* eslint-disable-next-line github/no-d-none */
        suggestion.classList.remove('d-none')

        // If we're showing at least one suggestion, also show the suggestions header.
        showSuggestionsHeader = true
      } else {
        /* eslint-disable-next-line github/no-d-none */
        suggestion.classList.add('d-none')
      }
    }

    /* eslint-disable-next-line github/no-d-none */
    suggestionsHeader.classList.toggle('d-none', !showSuggestionsHeader)
  }
}

export function hideSuggestions(): void {
  const suggestionsContainer = document.querySelector('.js-card-filter-suggester')

  if (suggestionsContainer instanceof HTMLElement) {
    suggestionsContainer.classList.remove('js-active-navigation-container')
    /* eslint-disable-next-line github/no-d-none */
    suggestionsContainer.classList.add('d-none')
  }
}

function clickFilterA11y(pressed: boolean, filterBy: string): void {
  // To prevent elements from getting out of sync with the current filter state,
  // we have to apply the changes to *all* of them.
  for (const el of document.querySelectorAll('.js-card-filter[data-card-filter]')) {
    if (el.getAttribute('data-card-filter') === filterBy) {
      el.setAttribute('aria-pressed', pressed.toString())
    }
  }
}

export function applyFilter(filterBy: string): void {
  const inputField = document.querySelector<HTMLInputElement>('.js-card-filter-input')!
  const currentFilter = inputField.value
  let queryString = filterBy
  let buttonPressed = true

  if (currentFilter.indexOf(queryString) >= 0) {
    // remove from existing filter
    buttonPressed = false
    queryString = currentFilter.replace(queryString, '')
  } else if (currentFilter.length) {
    // add to an existing filter
    queryString = `${currentFilter.trim()} ${queryString}`
  }

  inputField.value = queryString
  clickFilterA11y(buttonPressed, filterBy)
  filterCards()
}
