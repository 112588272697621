import {animationFrame} from '@github-ui/eventloop-tasks'

// Based on FLIP animation technique.
//   https://aerotwist.com/blog/flip-your-animations/

interface PositionMap<K, V> {
  get(key: K): V | undefined
}

export function recordPositions(nodeList: NodeListOf<HTMLElement>): PositionMap<HTMLElement, DOMRect> {
  const positions = new WeakMap()
  for (const card of nodeList) {
    positions.set(card, card.getBoundingClientRect())
  }
  return positions
}

async function invertPosition(
  el: HTMLElement,
  oldPositions: PositionMap<HTMLElement, DOMRect>,
  newPositions: PositionMap<HTMLElement, DOMRect>,
  transformTime = 500,
) {
  const oldPosition = oldPositions.get(el)!
  const newPosition = newPositions.get(el)!

  const deltaX = oldPosition.left - newPosition.left
  const deltaY = oldPosition.top - newPosition.top

  if (deltaX === 0 && deltaY === 0) {
    return
  }

  await animationFrame()

  el.style.transform = `translateZ(0) translate(${deltaX}px, ${deltaY}px)`
  el.style.transition = 'transform 0s'

  await animationFrame()

  el.style.transform = ''
  el.style.transition = `transform ${transformTime}ms`

  await new Promise(resolve => el.addEventListener('transitionend', resolve, {once: true}))

  el.style.transition = ''
}

export function animate(
  nodeList: NodeListOf<HTMLElement>,
  originalPositions: PositionMap<HTMLElement, DOMRect>,
  transformTime = 500,
): Promise<unknown> {
  const newPositions = recordPositions(nodeList)
  const animations = []
  for (const el of nodeList) {
    animations.push(invertPosition(el, originalPositions, newPositions, transformTime))
  }
  return Promise.all(animations)
}
